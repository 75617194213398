import { showErrorNotification, showDoneNotification } from '@ravnur/notifications/service';
import { setup, Vue } from 'vue-class-component';

import useJobsStore from '@/store/jobs';
import useSecurityStore from '@/store/security';
import useUploadStore from '@/store/upload';
import { Job$Status, Job$Type } from '@/types/Job';

import './ravnur-admin.scss';

export const JOBS_PING_TIMEOUT = 10000;

const NOTIFICATIONS_MAP: {
  [type in Job$Type]?: { [state in Job$Status]?: string };
} = {
  trimming: {
    [Job$Status.SUCCESS]: 'notification_center__success_trimming',
    [Job$Status.ERROR]: 'notification_center__failed_trimming',
  },
  retranscoding: {
    [Job$Status.SUCCESS]: 'notification_center__success_retranscoding',
    [Job$Status.ERROR]: 'notification_center__failed_retranscoding',
  },
  newVersionUpload: {
    [Job$Status.SUCCESS]: 'notification_center__success_new-version',
    [Job$Status.ERROR]: 'notification_center__failed_new-version',
  },
};

export default class JobPullingManager extends Vue {
  private security = setup(() => useSecurityStore());
  private upload = setup(() => useUploadStore());
  private jobs = setup(() => useJobsStore());

  created() {
    this.refreshJobs();
  }

  private async refreshJobs() {
    try {
      const jobs = await this.jobs.refresh();
      jobs.forEach(({ state, type, mediaId }) => {
        const cache = NOTIFICATIONS_MAP[type];
        const tkey = cache ? cache[state] : null;

        if (tkey) {
          const message = this.$t('common', tkey, { mediaId });

          if (state === Job$Status.SUCCESS) {
            showDoneNotification(message);
          } else {
            showErrorNotification(message);
          }
        }
      });
    } catch (e) {
      this.$logger.error(e);
    }
    setTimeout(this.refreshJobs, JOBS_PING_TIMEOUT);
  }

  render() {
    // noop
  }
}
