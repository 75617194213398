import { get } from '@/helpers/http';
import { OperationEntity } from '@/types/Operation';

if (process.env.VUE_APP_MOCK_API === 'yes') {
  (function _bootstrap() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const adapert = require('../mocks/index').default;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fnc = require('../mocks/operations').default;
    fnc(adapert);
  })();
}

export default class OperationRepository {
  public get(id: string): Promise<OperationEntity> {
    return get(`/operation/${id}`);
  }
}
