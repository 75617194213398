import { renderSlot } from 'vue';
import { Vue, Options, setup } from 'vue-class-component';

import { UNSAVED_REGISTRY_CTX } from '@/constants';
import ModalService from '@/helpers/modal.service';
import useSecurityStore from '@/store/security';

@Options({
  provide(this: UnsavedChecker) {
    return {
      [UNSAVED_REGISTRY_CTX]: {
        registry: this.registry,
        unregistry: this.unregistry,
        confirmLeavingIfNeeded: this.confirmLeavingIfNeeded,
      },
    };
  },
})
export default class UnsavedChecker extends Vue {
  private stack: Array<() => boolean> = [];
  private security = setup(() => useSecurityStore());

  private async confirmLeavingIfNeeded() {
    if (!this.stack.some((c) => c())) {
      return;
    }
    const msg = this.$t('common', 'edit-page__confirm-leaving');
    const res = await ModalService.confirm(msg);
    if (res) {
      return;
    }
    return Promise.reject(void 0);
  }

  created() {
    this.$router.beforeEach((to, redirect, next) => {
      if (!to || !to.name || !this.security.isAuth) {
        this.confirmLeavingIfNeeded().then(next);
      } else {
        if (!to.params.canceling) {
          return this.confirmLeavingIfNeeded().then(next);
        }
      }
      return next();
    });
  }

  render() {
    return renderSlot(this.$slots, 'default');
  }

  protected registry(hasUnsavedData: () => boolean) {
    this.stack.push(hasUnsavedData);
  }

  protected unregistry(hasUnsavedData: () => boolean) {
    this.stack = this.stack.filter((c) => c !== hasUnsavedData);
  }
}
