import registryCoreKit from '@ravnur/core/ui-kit';
import { App } from 'vue';

import ActionButton from './action-button/action-button';
import Card from './card/card';
import Column from './column/column';
import DownloadButton from './download-button/download-button';
import EmbedApp from './embed-app/embed-app';
import FileButton from './file-button/file-button';
import MediaIcon from './media-icon/media-icon';
import Thumbnail from './thumbnail/thumbnail';
import Messages from '@ravnur/shared/components/messages/messages';

import Grid from '@/ui-kit/grid/grid';

export default function registry(app: App) {
  registryCoreKit(app);
  app.component('action-button', ActionButton);
  app.component('card', Card);
  app.component('column', Column);
  app.component('download-button', DownloadButton);
  app.component('embed-app', EmbedApp);
  app.component('file-button', FileButton);
  app.component('grid', Grid);
  app.component('media-icon', MediaIcon);
  app.component('thumbnail', Thumbnail);
  app.component('messages', Messages);
}
