import { SecurityStore } from './store/security';

export enum EntityRouteKey {
  CREATE = 'create',
  EDIT = 'edit',
  LIST = 'list',
  OTHER = 'other',
}

export type EntityRoute = {
  [key in EntityRouteKey]?: string;
};

export enum RouteVisibility {
  ALL = 'all',
  ROOT = 'root',
  TENANT = 'tenant',
}

export type RavnurRouteConfig = RavnurRouteLeafConfig | RavnurRouteBranchConfig;

export type PingService = {
  route: string;
  filter: (item: any) => boolean;
  interval: number;
};

export interface RavnurRouteLeafConfig {
  enabledRouteRestriction?: keyof SecurityStore;
  allowedRouteRestriction?: keyof SecurityStore;
  routes: EntityRoute;
  tkey: string;
  icon: string;
  visibility?: RouteVisibility;
  order?: number;
  pingService?: PingService;
}

export interface RavnurRouteBranchConfig {
  type: 'branch';
  enabledRouteRestriction?: keyof SecurityStore;
  allowedRouteRestriction?: keyof SecurityStore;
  tkey: string;
  icon: string;
  visibility?: RouteVisibility;
  leafs: RavnurRouteLeafConfig[];
  route: string;
  expanded: boolean;
  order?: number;
}

export const DASHBOARD: RavnurRouteConfig = {
  tkey: 'menu__dashboard',
  icon: 'dashboard',
  visibility: RouteVisibility.ALL,
  order: 0,
  routes: {
    [EntityRouteKey.LIST]: 'Dashboard',
  },
};

export const ROLE: RavnurRouteConfig = {
  allowedRouteRestriction: 'isManageRolesAllowed',
  enabledRouteRestriction: 'isManageSettingsAllowed',
  tkey: 'menu__roles',
  icon: 'assignment',
  visibility: RouteVisibility.ALL,
  routes: {
    [EntityRouteKey.CREATE]: 'CreateRole',
    [EntityRouteKey.EDIT]: 'EditRole',
    [EntityRouteKey.LIST]: 'Roles',
  },
};

export const CONFIGURATIONS: RavnurRouteConfig = {
  enabledRouteRestriction: 'isManageSettingsAllowed',
  tkey: 'menu__configurations',
  icon: 'configurations',
  visibility: RouteVisibility.ROOT,
  routes: {
    [EntityRouteKey.LIST]: 'Configurations',
  },
};

export const BRANDING: RavnurRouteConfig = {
  tkey: 'menu__branding',
  icon: 'palette',
  enabledRouteRestriction: 'isManageSettingsAllowed',
  visibility: RouteVisibility.ROOT,
  routes: {
    [EntityRouteKey.LIST]: 'Branding',
  },
};

export const CC_AUTO_TRANSLATION: RavnurRouteConfig = {
  enabledRouteRestriction: 'isEnableCcAutoTranslationsAvailable',
  allowedRouteRestriction: 'isManageSettingsAllowed',
  tkey: 'header__languages',
  icon: 'language',
  visibility: RouteVisibility.ALL,
  routes: {
    [EntityRouteKey.LIST]: 'Languages',
  },
};

export const DOMAIN: RavnurRouteConfig = {
  enabledRouteRestriction: 'isManageSettingsAllowed',
  tkey: 'header__domain',
  icon: 'dns',
  visibility: RouteVisibility.ALL,
  routes: {
    [EntityRouteKey.LIST]: 'Domains',
    [EntityRouteKey.CREATE]: 'CreateDomain',
    [EntityRouteKey.EDIT]: 'EditDomain',
  },
};

export const TAG: RavnurRouteLeafConfig = {
  allowedRouteRestriction: 'isManageTagsAllowed',
  enabledRouteRestriction: 'isManageTagsEnabled',
  tkey: 'menu__tags',
  icon: 'widgets',
  visibility: RouteVisibility.ALL,
  routes: {
    [EntityRouteKey.CREATE]: 'CreateTag',
    [EntityRouteKey.EDIT]: 'EditTag',
    [EntityRouteKey.LIST]: 'Tags',
  },
};

export const MEMBER: RavnurRouteLeafConfig = {
  tkey: 'menu__members',
  icon: 'user',
  allowedRouteRestriction: 'isManageUsersAllowed',
  visibility: RouteVisibility.ALL,
  order: 5,
  routes: {
    [EntityRouteKey.EDIT]: 'MemberDetails',
    [EntityRouteKey.LIST]: 'Members',
    [EntityRouteKey.CREATE]: 'MemberCreate',
  },
};

export const FEATURED_VIDEOS: RavnurRouteConfig = {
  tkey: 'menu__featured',
  icon: 'releases',
  enabledRouteRestriction: 'isManageFeaturedVideosEnabled',
  allowedRouteRestriction: 'isManageFeaturedVideosAllowed',
  visibility: RouteVisibility.TENANT,

  routes: {
    [EntityRouteKey.LIST]: 'FeaturedVideos',
    [EntityRouteKey.EDIT]: 'VideoDetails',
  },
};

export const AUDIO: RavnurRouteLeafConfig = {
  tkey: 'menu__audio',
  icon: 'audio',
  enabledRouteRestriction: 'isManageAudiosEnabled',
  allowedRouteRestriction: 'isManageAudiosAllowed',
  visibility: RouteVisibility.ALL,
  order: 2,
  routes: {
    [EntityRouteKey.LIST]: 'Audios',
    [EntityRouteKey.EDIT]: 'AudioDetails',
  },
};

export const VIDEO: RavnurRouteLeafConfig = {
  tkey: 'menu__video',
  icon: 'video',
  enabledRouteRestriction: 'isManageVideosEnabled',
  allowedRouteRestriction: 'isManageVideosAllowed',
  visibility: RouteVisibility.ALL,
  order: 1,
  routes: {
    [EntityRouteKey.CREATE]: 'Upload',
    [EntityRouteKey.LIST]: 'Videos',
    [EntityRouteKey.EDIT]: 'VideoDetails',
    [EntityRouteKey.OTHER]: 'VideoEditors',
  },
};

export const DOCUMENT: RavnurRouteLeafConfig = {
  tkey: 'menu__documents',
  icon: 'document',
  enabledRouteRestriction: 'isDocumentsAvailable',
  visibility: RouteVisibility.ALL,
  routes: {
    [EntityRouteKey.LIST]: 'Documents',
    [EntityRouteKey.EDIT]: 'DocumentDetails',
  },
};

export const PLAYLIST: RavnurRouteLeafConfig = {
  tkey: 'menu__playlists',
  icon: 'list',
  enabledRouteRestriction: 'isManagePlaylistsEnabled',
  allowedRouteRestriction: 'isManagePlaylistsAllowed',
  visibility: RouteVisibility.ALL,
  order: 3,
  routes: {
    [EntityRouteKey.LIST]: 'Playlists',
    [EntityRouteKey.CREATE]: 'CreatePlaylist',
    [EntityRouteKey.EDIT]: 'PlaylistDetails',
  },
};

export const MAIL_TEMPLATE: RavnurRouteConfig = {
  tkey: 'header__mail-template',
  icon: 'mail',
  enabledRouteRestriction: 'isSettingAvailable',
  visibility: RouteVisibility.ALL,
  routes: {
    [EntityRouteKey.LIST]: 'MailTemplates',
    [EntityRouteKey.EDIT]: 'EditMailTemplate',
  },
};

export const GROUPS: RavnurRouteConfig = {
  tkey: 'menu__groups',
  icon: 'group',
  visibility: RouteVisibility.TENANT,
  enabledRouteRestriction: 'isManageGroupsEnabled',
  allowedRouteRestriction: 'isManageGroupsAllowed',
  order: 7,
  routes: {
    [EntityRouteKey.LIST]: 'Groups',
    [EntityRouteKey.CREATE]: 'CreateGroup',
    [EntityRouteKey.EDIT]: 'GroupDetails',
  },
};

export const CATEGORIES: RavnurRouteLeafConfig = {
  tkey: 'menu__categories',
  icon: 'category',
  visibility: RouteVisibility.TENANT,
  enabledRouteRestriction: 'isManageCategoriesEnabled',
  allowedRouteRestriction: 'isManageCategoriesAllowed',
  routes: {
    [EntityRouteKey.LIST]: 'Categories',
    [EntityRouteKey.CREATE]: 'CreateCategory',
    [EntityRouteKey.EDIT]: 'CategoryDetails',
  },
};

export const FEATURED_CAROUSEL: RavnurRouteConfig = {
  tkey: 'menu__featured-carousels',
  icon: 'carousel',
  visibility: RouteVisibility.TENANT,
  enabledRouteRestriction: 'isManageFeaturedCarouselsEnabled',
  allowedRouteRestriction: 'isManageFeaturedCarouselsAllowed',
  routes: {
    [EntityRouteKey.LIST]: 'FeaturedCarousel',
    [EntityRouteKey.EDIT]: 'EditFeaturedCarousel',
    [EntityRouteKey.CREATE]: 'CreateFeaturedCarousel',
  },
};

export const HOME_PAGE: RavnurRouteBranchConfig = {
  tkey: 'menu__home-page',
  icon: 'home',
  expanded: false,
  leafs: [FEATURED_CAROUSEL, FEATURED_VIDEOS],
  type: 'branch',
  route: '/home',
  order: 10,
};

export const ZOOM_IMPORT: RavnurRouteConfig = {
  tkey: 'menu__zoom-import',
  icon: 'zoom',
  routes: {
    [EntityRouteKey.LIST]: 'ZoomImport',
  },
};

export const CHANGE_OWNER: RavnurRouteConfig = {
  tkey: 'menu__change-owner',
  icon: 'change-owner',
  routes: {
    [EntityRouteKey.LIST]: 'ChangeOwner',
  },
};

export const TOOLS: RavnurRouteBranchConfig = {
  tkey: 'menu__tools',
  icon: 'tools',
  expanded: false,
  leafs: [ZOOM_IMPORT, CHANGE_OWNER],
  type: 'branch',
  route: '/tools',
  order: 11,
};

export const SETTINGS: RavnurRouteBranchConfig = {
  tkey: 'header__settings',
  icon: 'settings',
  enabledRouteRestriction: 'isManageSettingsAllowed',
  expanded: false,
  leafs: [BRANDING, ROLE, CC_AUTO_TRANSLATION, DOMAIN, CONFIGURATIONS],
  type: 'branch',
  route: '/settings',
  order: 12,
};

export const TRANSCODING: RavnurRouteConfig = {
  tkey: 'header__transcoding',
  icon: 'processor',
  visibility: RouteVisibility.ALL,
  order: 6,
  routes: {
    [EntityRouteKey.LIST]: 'Transcoding',
  },
};

export const MENU: RavnurRouteConfig[] = [
  DASHBOARD,
  VIDEO,
  AUDIO,
  DOCUMENT,
  PLAYLIST,
  MEMBER,
  GROUPS,
  TOOLS,
  HOME_PAGE,
  TRANSCODING,
  SETTINGS,
];

export function isRouteConfig(config: unknown): config is RavnurRouteConfig {
  if (!config) {
    return false;
  }
  const c = config as Dictionary<unknown>;
  return !!c.routes && !!c.tkey && !!c.tkey;
}

export function isEnabledRoute(security: SecurityStore, config: RavnurRouteConfig) {
  const { enabledRouteRestriction } = config;
  return !(enabledRouteRestriction && !security[enabledRouteRestriction]);
}

export function isAllowedRoute(security: SecurityStore, config: RavnurRouteConfig) {
  const { allowedRouteRestriction } = config;
  return !(allowedRouteRestriction && !security[allowedRouteRestriction]);
}

export function isVisibleRoute(security: SecurityStore, config: RavnurRouteConfig) {
  const { visibility } = config;
  const isRoot = security.isRootApplication;
  return visibility !== (isRoot ? RouteVisibility.TENANT : RouteVisibility.ROOT);
}

export function isAvailableRoute(security: SecurityStore, config: RavnurRouteConfig) {
  if (!isEnabledRoute(security, config) || !isAllowedRoute(security, config)) {
    return false;
  }
  return isVisibleRoute(security, config);
}

export function isRouteBranchConfig(config: RavnurRouteConfig): config is RavnurRouteBranchConfig {
  return (
    (config as RavnurRouteBranchConfig).type === 'branch' &&
    (config as RavnurRouteBranchConfig).leafs instanceof Array
  );
}

export function isRouteLeafConfig(config: unknown): config is RavnurRouteLeafConfig {
  return isRouteConfig(config) && !isRouteBranchConfig(config);
}
