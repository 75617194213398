import { get, post, put, remove } from '@/helpers/http';
import { SortType } from '@/transformers/sort-by';
import { Job } from '@/types/Job';
import {
  Media,
  Media$Privacy,
  Media$Type,
  Media$Details,
  Media$Availability,
  Media$Version,
  Media$Quality,
  TrimInfo,
  ClipInfo,
} from '@/types/Media';
import { TimedataAbbreviation } from '@ravnur/hooks/video-editors/useTimedataEditor';
import { Timedata, TimedataSegment, TimedataThumbnail } from '@ravnur/shared/types/Media';

import BaseRepository, { PagedListResponse } from './base-repository';
import { Operation$Status, OperationEntity } from '@/types/Operation';
import axios from 'axios';
import { decodeVtt } from '@ravnur/helpers/vtt';

export interface MediaQueryParams {
  q: string;
  type: Media$Type;
  privacy: Media$Privacy | Media$Privacy[];
  availability: Media$Availability;
  sortField: keyof Media;
  sortType: SortType;
  sort: string;
  owner: string;
  offset: number;
  count: number;

  folderId: string;
  groupId: string;
  exceptGroup: boolean;
  loadEmptyPlaylists?: boolean; // true by default
}

if (process.env.VUE_APP_MOCK_API === 'yes') {
  (function _bootstrap() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const adapter = require('../mocks/index').default;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fnc = require('../mocks/media').default;
    fnc(adapter);
  })();
}

export default class MediaRepository extends BaseRepository<
  Media$Details,
  MediaQueryParams,
  PagedListResponse<Media>
> {
  constructor() {
    super('/media');
  }

  public async versions({ id }: Media): Promise<{
    versions: Media$Version[];
    qualities: Media$Quality[];
  }> {
    return get(`${this._resource}/${id}/version`);
  }

  public async undoUploading(mediaId: string, options: Dictionary<unknown> = {}) {
    return this.undo(mediaId, 'upload', options);
  }

  public uploadThumbnail(file: File, { id }: Media) {
    return this._upload(file, `${id}/thumbnail`);
  }

  public process(mediaId: string, versionId?: string) {
    const param = versionId ? `?versionId=${versionId}` : ``;
    return post(`${this._resource}/${mediaId}/process` + param, {});
  }

  public async cancelUpload(files: Pick<Media, 'id'>[]) {
    return post(`${this._resource}/purge`, files);
  }

  public cancelJob(mediaId: string) {
    return remove(`${this._resource}/${mediaId}/job`);
  }

  public trimVideo(id: string, info: TrimInfo) {
    return this.action({ id }, 'job/trimming', info);
  }

  public makeClip(id: string, info: ClipInfo) {
    return this.action({ id }, 'job/clipping', info);
  }

  public getTimedata(mediaId: string) {
    return get(`${this._resource}/${mediaId}/cc`);
  }

  public async getTimedataByLanguage(
    mediaId: string,
    language: string,
    timedataAbr: TimedataAbbreviation
  ): Promise<Timedata> {
    return get(`${this._resource}/${mediaId}/${timedataAbr}/${language}`);
  }

  public updateTimedata(
    mediaId: string,
    language: string,
    segments: TimedataSegment[],
    timedataAbr: TimedataAbbreviation
  ) {
    return put(`${this._resource}/${mediaId}/${timedataAbr}/${language}`, segments);
  }

  public toggleTimedataState(
    mediaId: string,
    language: string,
    state: string,
    timedataAbr: TimedataAbbreviation
  ) {
    return post(`${this._resource}/${mediaId}/${timedataAbr}/${language}/${state}`, {});
  }

  public getTranscriptByLanguage(mediaId: string, language: string): Promise<string> {
    return get(`${this._resource}/${mediaId}/transcript/${language}`);
  }

  public createNewCuePoint(mediaId: string, language: string, data: Partial<TimedataSegment>[]) {
    return post(`${this._resource}/${mediaId}/cp/${language}`, data);
  }

  public deleteTimedata(mediaId: string, language: string, timedataAbr: TimedataAbbreviation) {
    return remove(`${this._resource}/${mediaId}/${timedataAbr}/${language}`);
  }

  public generateCC(mediaId: string, language: string) {
    return post(`${this._resource}/${mediaId}/index/${language}`, {});
  }

  public transfer(
    userName: string,
    oldUserName: string,
    mediaIds: string[],
    exceptMediaIds: string[] = [],
    transferAll: boolean
  ) {
    const path = transferAll ? 'transfer' : 'owner';
    const data = transferAll ? { oldUserName, userName, exceptMediaIds } : { userName, mediaIds };
    return post(`/tools${this._resource}/${path}`, data);
  }

  public getAudioIndexingJobs(mediaId: string): Promise<Job[]> {
    return get(`${this._resource}/${mediaId}/job`);
  }

  public async generateAiDescription(mediaId: string): Promise<{ description: string }> {
    const response: OperationEntity<{ description: string }> = await get(
      `${this._resource}/${mediaId}/aidescription`
    );

    if (response.state === Operation$Status.FAILED) {
      throw new Error(response.errorReason[0]);
    } else {
      return response.data;
    }
  }

  public async generateAiChapters(mediaId: string): Promise<Timedata> {
    const response: OperationEntity<Timedata> = await get(
      `${this._resource}/${mediaId}/aichapters`
    );

    if (response.state === Operation$Status.FAILED) {
      throw new Error(response.errorReason[0]);
    } else {
      return response.data;
    }
  }

  public async getThumbnailsFromPreview(previewUrl: string): Promise<TimedataThumbnail[]> {
    const vttResponse = await axios.get(previewUrl);
    return decodeVtt(vttResponse.data) as TimedataThumbnail[];
  }
}
