import { $Props } from '@ravnur/core/typings/tsx';
import { RButton$Props } from '@ravnur/core/ui-kit/button/button';
import { showErrorNotification } from '@ravnur/notifications/service';
import { renderSlot } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

import './file-button.scss';

const CN = 'file-button';

class Props {
  accept = prop({ default: 'image/gif,image/jpeg,image/png' });
  onFileSelected: (file: File) => Promise<void>;
}

export type FileButton$Props = $Props<Props> & Partial<Omit<RButton$Props, 'onclick'>>;

@Options({
  name: 'file-button',
  emits: ['click'],
})
export default class FileButton extends Vue.with(Props) {
  declare $refs: {
    uploader: HTMLInputElement;
  };

  render() {
    const { $attrs, accept } = this;
    return (
      <div class={CN}>
        <r-button class={`${CN}__btn`} {...$attrs} onclick={this.handleClickEvent}>
          {renderSlot(this.$slots, 'default')}
        </r-button>

        <input
          ref="uploader"
          accept={accept}
          class={`${CN}__hidden`}
          type="file"
          onChange={this.handleFileChanged}
        />
      </div>
    );
  }

  public clear() {
    const { uploader } = this.$refs;
    if (uploader instanceof HTMLInputElement) {
      uploader.value = '';
    }
  }

  private async handleClickEvent() {
    const { uploader } = this.$refs;
    if (uploader instanceof HTMLInputElement) {
      uploader.click();
    }
  }

  private async handleFileChanged() {
    const { uploader } = this.$refs;
    if (!(uploader instanceof HTMLInputElement)) {
      return;
    }
    const files = uploader.files;
    if (!files || !files.length) {
      return;
    }

    const file = files.item(0);
    if (file) {
      if (this.isValidFileType(file.type)) {
        await this.onFileSelected(file);
      } else {
        showErrorNotification(this.$t('common', 'file__incorrect-format'));
      }
    }
    uploader.value = '';
  }

  private isValidFileType(type: string) {
    return this.accept.split(',').some((t) => t === type);
  }
}
