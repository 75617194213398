import { $Props } from '@ravnur/core/typings/tsx';
import { Watch } from '@ravnur/decorators';
import { showErrorNotification } from '@ravnur/notifications/service';
import { useVuelidate } from '@vuelidate/core';

import { AxiosError } from 'axios';
import { Vue, Options, setup } from 'vue-class-component';

import MediaJobProgressbar from '@/components/media/media-job-progressbar/media-job-progressbar';
import MediaUploadProgressbar from '@/components/media/media-upload-progressbar/media-upload-progressbar';
import ModalService from '@/helpers/modal.service';
import UploadService from '@/helpers/upload-service';
import { required } from '@/helpers/validations.rules';
import MediaRepository from '@/repositories/media-repository';
import useJobsStore from '@/store/jobs';
import { StandardOption } from '@/types/Component';
import { Job, Job$Status } from '@/types/Job';
import {
  Media$Privacy,
  PRIVACY_LABELS,
  PRIVACIES,
  Media$Type,
  Media$Details,
  PUBLIC_APP_PRIVACIES,
} from '@/types/Media';
import { UploadStatus, UploadInfo } from '@/types/Upload';
import useSecurityStore from '@/store/security';
import { LookupEntity } from '@/types/LookupEntity';

import './media-upload-card.scss';
import { Language } from '@ravnur/shared/types/Language';
import { languagesService } from '@/modules/common-services';

const CN = 'media-upload-card';

const repository = new MediaRepository();

const GONE_HTTP_STATUS = 410;

class Props {
  item: UploadInfo;
}

type Emits = {
  onChange: ({ state, item }: { state: string; item: UploadInfo }) => void;
};

@Options({
  watch: {
    mediaId: 'fetch',
  },
  inject: {
    uploadService: 'UPLOAD_SERVICE',
  },
  validations: {
    title: { required, $autoDirty: true },
  },
  emits: ['change'],
})
export default class MediaUploadCard extends Vue.with(Props) {
  declare uploadService: UploadService;
  declare $props: $Props<Props, Emits>;

  public v$ = setup(() => useVuelidate());
  private jobs = setup(() => useJobsStore());
  private security = setup(() => useSecurityStore());

  private origin: Nullable<Partial<Media$Details>> = null;
  private title = '';
  private privacy: Media$Privacy = Media$Privacy.PUBLIC;
  private isAutoGenerateCaption = false;
  private languageId = '';
  private saving = false;
  private saved = true;
  private failedToSave = false;
  private saveTimeOutId: NodeJS.Timeout;
  private saveMessageTimeOutId: NodeJS.Timeout;
  private requestLoading = false;
  private loadingMessage = '';

  get mediaId() {
    return this.item.mediaId;
  }

  get job(): Nullable<Job> {
    const { mediaId } = this;

    return this.jobs.running[mediaId];
  }

  get latestJob(): Nullable<Job> {
    const { mediaId } = this;
    return this.job || this.jobs.log[mediaId];
  }

  get isProcessingFinished() {
    const { mediaId } = this;
    const logJob = this.jobs.log[mediaId];
    return !this.job && logJob && logJob.state !== Job$Status.ERROR;
  }

  get privacies(): StandardOption[] {
    return this.security.isPublicApplication
      ? PUBLIC_APP_PRIVACIES.map(this.privacy2option)
      : PRIVACIES.map(this.privacy2option);
  }

  get languages(): Language[] {
    return languagesService.list.filter((l) => l.indexable);
  }

  get isInvalid(): boolean {
    return this.v$.$invalid;
  }

  get isUploadFinished() {
    return this.item.status === UploadStatus.FINISHED;
  }

  get renderSavedMessage() {
    if (!this.mediaId) return;
    if (!this.saved && !this.saving && !this.failedToSave) return '';

    if (this.failedToSave) {
      return (
        <div class={`${CN}__error`}>
          <l10n group="media" tkey="upload-card__failed-to-save" />
        </div>
      );
    }

    if (this.saved) {
      return (
        <div class={`${CN}__success`}>
          <icon size="sm" type="info" />
          <l10n group="media" tkey="upload-card__saved" />
        </div>
      );
    }

    if (this.saving) {
      return (
        <div class={`${CN}__success`}>
          <spinner />
          <l10n group="media" tkey="upload-card__saving" />
        </div>
      );
    }
  }

  get fileName() {
    return this.uploadService.getFileName(this.item.uid);
  }

  get isAudio() {
    return this.item.mediaType === Media$Type.AUDIO;
  }

  @Watch('job')
  onJobChange(newValue: Job, oldValue: Job) {
    if (oldValue && !newValue) {
      ModalService.closeModal();
    }
  }

  @Watch('item.status')
  onStatusChange(newValue: UploadStatus) {
    if (newValue == UploadStatus.FAILED) {
      this.$emit('change', { state: 'failed', item: this.item });
    }
  }

  @Watch('privacy')
  onPrivacyChange(newValue: string) {
    if (this.origin && this.origin.privacy === newValue) return;
    this.triggerSave();
  }

  @Watch('title')
  onTitleChange(newValue: string) {
    if (this.origin && this.origin.title === newValue) return;
    this.triggerSave();
  }

  @Watch('isAutoGenerateCaption')
  onIsAutoGenerateCaptionChange(newValue: boolean) {
    if (this.origin?.isAutoGenerateCaption === newValue) return;
    this.triggerSave();
  }

  @Watch('languageId')
  onlanguageId(newValue: string) {
    if (this.origin?.languageId === newValue) return;
    this.triggerSave();
  }

  render() {
    return (
      <card class={CN} title={this.fileName} v-slots={{ header: this.renderSavedMessage }}>
        {this.isUploadFinished ? (
          <MediaJobProgressbar
            item={this.job}
            loading={this.requestLoading}
            mediaId={this.mediaId}
            onCancel={this.onCancelJob}
            onDelete={this.onDeleteJob}
            onRetry={this.onRetryProcess}
          />
        ) : (
          <MediaUploadProgressbar
            item={this.item}
            onCancel={this.onCancelUpload}
            onPause={this.onPause}
            onResume={this.onResume}
            onRetry={this.onRetry}
          />
        )}
        {!this.mediaId ? this.renderLoadingOverlay() : this.renderForm()}
      </card>
    );
  }

  renderForm() {
    return (
      <>
        <div class={`${CN}__complete-message`} v-show={this.isUploadFinished}>
          <l10n group="media" tkey="upload-card__complete-message" />
        </div>
        <text-field
          class={`${CN}__title-field`}
          errors={this.v$.title.$errors}
          label={this.$t('media', 'field__title')}
          maxLength={100}
          vModel={[this.title, 'value']}
        />
        <chosen
          class={`${CN}__privacy-field`}
          label={this.$t('media', 'field__privacy')}
          options={this.privacies}
          vModel={[this.privacy, 'value']}
        />
        {this.security.isAutoCaptionsAvailable && !this.isAudio && (
          <div class={`${CN}__captions-fields`}>
            <div class={`${CN}__captions-field`}>
              <checkbox
                disabled={this.isProcessingFinished}
                label={this.$t('media', 'field__generate-captions')}
                vModel={[this.isAutoGenerateCaption, 'value']}
              />
            </div>
            <chosen
              class={`${CN}__language-field`}
              disabled={this.isProcessingFinished}
              keyForLabel="localName"
              label={this.$t('media', 'field__language')}
              options={this.languages}
              v-show={this.isAutoGenerateCaption}
              vModel={[this.languageId, 'value']}
            />
          </div>
        )}
        <div class={`${CN}__actions`}>
          <r-button color="primary" onclick={this.toDetails}>
            <l10n group="media" tkey="upload-card__edit-page" />
          </r-button>
        </div>
      </>
    );
  }

  renderLoadingOverlay() {
    return (
      <div class={`${CN}__loading-overlay`}>
        <div class={`${CN}__loading-overlay--text`}>
          {this.loadingMessage}
          <spinner size="sm" />
        </div>
      </div>
    );
  }

  generateLoadingMessages() {
    let messageIndex = 0;
    const labelKeys = [
      'media__loading-check-file',
      'media__loading-validating-format',
      'media__loading-almost-ready',
    ];

    this.loadingMessage = this.$t('media', labelKeys[messageIndex]);

    const intervalId = setInterval(() => {
      if (messageIndex > labelKeys.length - 1) {
        clearInterval(intervalId);

        return;
      }

      this.loadingMessage = this.$t('media', labelKeys[messageIndex]);
      messageIndex = messageIndex + 1;
    }, 4000);
  }

  async mounted() {
    this.generateLoadingMessages();
    this.fetch();
  }

  async fetch() {
    if (!this.mediaId) {
      return;
    }

    const media = await repository.get(this.mediaId);
    this.origin = media;
    this.isAutoGenerateCaption = media.isAutoGenerateCaption;
    this.languageId = media.languageId;
    this.title = media.title;
    this.privacy = media.privacy;
  }

  private privacy2option(privacy: Media$Privacy): StandardOption {
    return { id: privacy, label: this.$t('media', PRIVACY_LABELS[privacy]) };
  }

  private toDetails() {
    const { mediaId } = this;

    let pageName = 'VideoDetails';

    if (this.isAudio) {
      pageName = 'AudioDetails';
    }

    this.$router.push({ name: pageName, params: { id: mediaId } });
  }

  private onPause() {
    this.$emit('change', { state: 'pause', item: this.item });
  }

  private onResume() {
    this.$emit('change', { state: 'resume', item: this.item });
  }

  private onRetry() {
    this.$emit('change', { state: 'retry', item: this.item });
  }

  private onCancelUpload() {
    this.$emit('change', { state: 'cancel', item: this.item });
  }

  private onDelete() {
    this.$emit('change', { state: 'delete', item: this.item });
  }

  private async onCancelJob() {
    const msg = this.$t('media', 'download__cancel-job-confirm');
    const confirmText = this.$t('media', 'processing__cancel-btn');
    const closeLabel = this.$t('media', 'processing__close-btn');
    const flag = await ModalService.confirm(msg, { confirmText, closeLabel }).catch(() => false);

    if (!flag || !this.job) return;

    this.requestLoading = true;

    try {
      await this.jobs.cancel(this.job);
    } catch (e: unknown) {
      if ((e as AxiosError).response && (e as AxiosError).response?.status === GONE_HTTP_STATUS) {
        showErrorNotification(
          this.$t('common', 'notification_center__prevent_canceling_job', this.job)
        );
      } else {
        this.$processException(e);
      }
    } finally {
      this.requestLoading = false;
    }
  }

  async onDeleteJob() {
    if (!this.latestJob) {
      return;
    }

    const msg = this.$t('media', 'download__delete-job-confirm');
    const confirmText = this.$t('media', 'processing__delete-btn');
    const closeLabel = this.$t('media', 'processing__close-btn');
    const flag = await ModalService.confirm(msg, { confirmText, closeLabel }).catch(() => false);

    if (!flag) return;

    this.requestLoading = true;

    try {
      await this.jobs.remove(this.latestJob);
      this.onDelete();
    } catch (e) {
      this.$processException(e);
    } finally {
      this.requestLoading = false;
    }
  }

  async onRetryProcess() {
    if (!this.latestJob) {
      return;
    }
    this.requestLoading = true;

    try {
      await this.jobs.retry(this.latestJob);
    } catch (e) {
      this.$processException(e);
    } finally {
      this.requestLoading = false;
    }
  }

  triggerSave() {
    this.saved = false;
    this.failedToSave = false;

    clearTimeout(this.saveTimeOutId);
    this.saveTimeOutId = setTimeout(() => this.save(), 2000);
  }

  async save() {
    const { title, privacy, security, isAutoGenerateCaption, languageId } = this;
    const { mediaId } = this;
    let allowedUsers: LookupEntity[] = [];

    if (this.isInvalid) return;

    this.origin = { title, privacy, isAutoGenerateCaption, languageId };

    if (privacy === Media$Privacy.RESTRICTED && security.user) {
      allowedUsers.push({
        id: security.user.id,
        name: security.user.displayName,
      });
    } else {
      allowedUsers = [];
    }

    this.saving = true;

    clearTimeout(this.saveMessageTimeOutId);

    // Delay added to keep "Saving changes" message little bit longer,
    // previously it disappears too quick because of API response speed.
    try {
      await repository.save({
        id: mediaId,
        title,
        privacy,
        allowedUsers,
        isAutoGenerateCaption,
        languageId,
      });

      this.saveMessageTimeOutId = setTimeout(() => {
        this.saving = false;
        this.saved = true;
      }, 3000);
    } catch (e) {
      this.saveMessageTimeOutId = setTimeout(() => {
        this.failedToSave = true;
        this.saving = false;
        this.$processException(e);
      }, 3000);
    }
  }
}
