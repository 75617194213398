import mitt from 'mitt';
import { VueConstructor, Vue } from 'vue-class-component';

interface Emits<R> {
  close?: () => void;
  confirm?: (payload: R) => void;
}

export type ModalConstructor<R, P extends Emits<R> = Emits<R>> = VueConstructor<
  Vue & { $props: P }
>;

type Props<MC extends ModalConstructor<any>> = Omit<InstanceType<MC>['$props'], keyof Emits<any>>;

export interface IModalManager {
  showModal<R, MC extends ModalConstructor<R>>(Modal: MC, props: Props<MC>): Promise<R>;
  closeModal(): void;
}

class ModalService {
  private manager: Nullable<IModalManager> = null;
  bus = mitt();

  registry(manager: IModalManager) {
    this.manager = manager;
  }

  showModal<R, MC extends ModalConstructor<R>>(Modal: MC, props: Props<MC>): Promise<R> {
    return this.manager?.showModal(Modal, props) || Promise.reject();
  }

  closeModal() {
    this.manager?.closeModal();
  }

  confirm(
    message: string,
    opts: Partial<{
      header?: string;
      confirmText: string;
      closeLabel: string;
    }> = {}
  ): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Modal = require('../components/confirm/confirm').default;
    return this.showModal(Modal, { ...opts, message });
  }
}

export default new ModalService();
