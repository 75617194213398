import { RouteRecordRaw, RouterView } from 'vue-router';

import {
  RavnurRouteLeafConfig,
  EntityRouteKey,
  RavnurRouteBranchConfig,
} from '@/entity-route-config';
import { LiveSource, LiveSource$Status } from './types/Live';

export const LIVE_SOURCES: RavnurRouteLeafConfig = {
  tkey: 'menu__live-sources',
  icon: 'sitemap',
  routes: {
    [EntityRouteKey.LIST]: 'LiveSources',
    [EntityRouteKey.CREATE]: 'CreateLiveSource',
    [EntityRouteKey.EDIT]: 'EditLiveSource',
  },
  pingService: {
    route: '/live/source',
    interval: 5000,
    filter: (item: LiveSource) => item.status === LiveSource$Status.RUNNING,
  },
};

export const LIVE_CHANNELS: RavnurRouteLeafConfig = {
  tkey: 'menu__live-channels',
  icon: 'rss',
  routes: {
    [EntityRouteKey.LIST]: 'LiveChannels',
    [EntityRouteKey.EDIT]: 'EditLiveChannel',
  },
};

export const LIVE_EVENTS: RavnurRouteLeafConfig = {
  tkey: 'menu__live-events',
  icon: 'event',
  routes: {
    [EntityRouteKey.LIST]: 'LiveEvents',
    [EntityRouteKey.EDIT]: 'LiveEventDetails',
  },
};

export const LIVE_TEMPLATES: RavnurRouteLeafConfig = {
  tkey: 'menu__live-templates',
  icon: 'template',
  routes: {
    [EntityRouteKey.LIST]: 'LiveTemplates',
  },
};

export const LIVE: RavnurRouteBranchConfig = {
  tkey: 'menu__live',
  icon: 'antenna',
  expanded: false,
  leafs: [LIVE_TEMPLATES, LIVE_EVENTS, LIVE_CHANNELS, LIVE_SOURCES],
  type: 'branch',
  route: '/live',
  order: 4,
};

export const ROUTES: RouteRecordRaw[] = [
  {
    path: '/live',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Live',
        component: RouterView,
      },
      {
        path: 'sources',
        meta: { translations: ['live-sources'], config: LIVE_SOURCES },
        component: RouterView,
        children: [
          {
            path: '',
            name: 'LiveSources',
            component: () => import('./pages/live-sources'),
          },
          {
            path: ':id',
            name: 'EditLiveSource',
            component: () => import('./pages/edit-live-source'),
          },
        ],
      },

      {
        path: 'templates',
        component: RouterView,
        meta: {
          config: LIVE_TEMPLATES,
        },
        children: [
          {
            path: '',
            name: 'LiveTemplates',
            component: () => import('./pages/live-templates'),
          },
        ],
      },

      {
        path: 'channels',
        component: RouterView,
        meta: {
          translations: ['live-channels', 'live-sources'],
          config: LIVE_CHANNELS,
        },
        children: [
          {
            path: '',
            name: 'LiveChannels',
            component: () => import('./pages/live-channels'),
          },
          {
            path: ':id',
            name: 'EditLiveChannel',
            component: () => import('./pages/edit-live-channel'),
          },
        ],
      },

      {
        path: 'events',
        component: RouterView,
        meta: {
          translations: ['live-channels', 'live-events', 'live-sources', 'media'],
          config: LIVE_EVENTS,
        },
        children: [
          {
            path: '',
            name: 'LiveEvents',
            component: () => import('./pages/live-events'),
          },
          {
            path: ':id',
            component: RouterView,
            children: [
              {
                path: '',
                name: 'LiveEventDetails',
                component: () => import('./pages/live-event-details'),
              },
              {
                path: 'control',
                name: 'LiveEventControlRoom',
                component: () => import('./pages/live-event-control-room'),
                meta: {
                  translations: [
                    'live-channels',
                    'live-events',
                    'live-sources',
                    'live-event-control-room',
                  ],
                  config: LIVE_EVENTS,
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
