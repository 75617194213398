import { detectCookieName } from '@ravnur/auth/helpers/detect-cookie-name';
import { setup, Vue } from 'vue-class-component';

import ApplicationChosen from '../application-chosen/application-chosen';

import {
  EntityRouteKey,
  isRouteLeafConfig,
  MAIL_TEMPLATE,
  RavnurRouteConfig,
} from '@/entity-route-config';
import useRootStore from '@/store/root';
import useSecurityStore from '@/store/security';
import useUploadStore from '@/store/upload';
import { UploadStatus } from '@/types/Upload';
import { ApplicationTypes } from '@ravnur/shared/types/Application';

import './app-header.scss';

const CN = 'app-header';

type HeaderAction =
  | {
      icon: string;
      tkey: string;
    }
  | RavnurRouteConfig;

const LOGOUT_ITEM: HeaderAction = { icon: 'logout', tkey: 'header__logout' };
const MY_ACC_ITEM: HeaderAction = { icon: 'user', tkey: 'header__my-account' };

const CONFIG_ITEM: HeaderAction = { icon: 'settings', tkey: 'header__config' };
const LANG_ITEM: HeaderAction = { icon: 'language', tkey: 'header__languages' };
const POLICY_ITEM: HeaderAction = { icon: 'help', tkey: 'header__policies' };

const CHANGE_OWNER_ITEM: HeaderAction = {
  icon: 'group',
  tkey: 'header__change-owner',
};
const CHANGE_DATE_ITEM: HeaderAction = {
  icon: 'date-range',
  tkey: 'header__change-date',
};
const URL_GEN_ITEM: HeaderAction = { icon: 'link', tkey: 'header__url-gen' };

const SETTINGS_ACTIONS: HeaderAction[] = [CONFIG_ITEM, MAIL_TEMPLATE, LANG_ITEM, POLICY_ITEM];
const TOOLS_ACTIONS: HeaderAction[] = [CHANGE_OWNER_ITEM, CHANGE_DATE_ITEM, URL_GEN_ITEM];

class Props {
  toggleApplicationMenu: () => void;
}

export default class AppHeader extends Vue.with(Props) {
  security = setup(() => useSecurityStore());
  store = setup(() => useRootStore());
  upload = setup(() => useUploadStore());

  private get getTotalProgress() {
    return this.upload.list.reduce((acc, item) => acc + item.progress, 0) / this.upload.list.length;
  }

  private get isUploadProcessing() {
    return this.upload.list.some((item) =>
      [UploadStatus.PROCESSING, UploadStatus.INITIALIZED].includes(item.status)
    );
  }

  private get currentUser() {
    return this.security.user;
  }

  private get isSettingAvailable() {
    return this.security.isSettingAvailable;
  }

  private get isToolsAvailable() {
    return this.security.isToolsAvailable;
  }

  private get isRootApplication() {
    return this.security.isRootApplication;
  }

  private get currentApplication() {
    return this.security.currentApplication;
  }

  private get portalUrl() {
    const { currentApplication: application } = this;
    return application ? `${application.portalUrl}/` : '';
  }

  private get myAccountUrl() {
    const { applications } = this.security;
    const myAccountApp = applications?.find(
      (app) => app.applicationType === ApplicationTypes.MY_ACCOUNT
    );
    return myAccountApp?.portalUrl ?? '';
  }

  private get profileActions(): HeaderAction[] {
    if (this.isRootApplication || this.security.isPublicApplication) {
      return [LOGOUT_ITEM];
    } else {
      return [MY_ACC_ITEM, LOGOUT_ITEM];
    }
  }

  protected toPortal() {
    this.replaceLocationToPortal(this.portalUrl);
  }

  protected doProfileAction(action: HeaderAction) {
    if (action === LOGOUT_ITEM) {
      this.store.logout();
    } else if (action === MY_ACC_ITEM) {
      this.replaceLocationToPortal(this.myAccountUrl);
    }
  }

  protected doSettingsAction(action: HeaderAction) {
    if (isRouteLeafConfig(action)) {
      this.$router.push({ name: action.routes[EntityRouteKey.LIST] });
    } else {
      this.$logger.debug(action);
    }
  }

  protected toApplication(path: string) {
    location.replace(`${path}/`);
  }

  render() {
    const { currentUser } = this;

    return (
      <header class={CN}>
        <div class={`${CN}__logo-wrapper`}>
          <ApplicationChosen class={`${CN}__applications`} onRedirect={this.toApplication} />
          {!this.isRootApplication && this.renderToPortalButton()}
          {this.isSettingAvailable &&
            this.renderSubMenu(SETTINGS_ACTIONS, 'header__settings', 'multi-settings')}
          {this.isToolsAvailable && this.renderSubMenu(TOOLS_ACTIONS, 'header__tools', 'build')}
        </div>

        <ul class={`${CN}__actions`}>
          <li class={`${CN}__action`}>
            <r-button
              class={{
                [`${CN}__upload-button--processing`]: this.isUploadProcessing,
                [`${CN}__upload-button`]: true,
              }}
              color="accent"
              icon="upload"
              mode="frameless"
              onclick={this.toUploadPage}
            >
              {this.isUploadProcessing ? (
                this.$t('media', 'download__uploading', {
                  progress: this.getTotalProgress.toFixed(0),
                })
              ) : (
                <l10n group="common" tkey="header__upload" />
              )}
            </r-button>
          </li>

          {process.env.VUE_APP_HELP_URL && (
            <li class={`${CN}__action ${CN}__help-button`}>
              <a href={process.env.VUE_APP_HELP_URL} rel="noreferrer" target="_blank">
                ?
              </a>
            </li>
          )}

          <li class={`${CN}__action ${CN}__profile-button`}>
            <more-actions
              options={this.profileActions}
              v-slots={{ option: this.renderActionOption }}
              onClick={this.doProfileAction}
            >
              {currentUser ? <avatar title="" user={currentUser} /> : null}
            </more-actions>
          </li>
        </ul>

        <r-button
          class={`${CN}__menu-btn`}
          icon="menu"
          mode="frameless"
          onclick={this.toggleApplicationMenu}
        >
          &nbsp;
        </r-button>

        <progressbar
          class={`${CN}__progressbar`}
          v-show={this.isUploadProcessing}
          value={this.getTotalProgress}
        />
      </header>
    );
  }

  private renderToPortalButton() {
    return (
      <r-button
        class={`${CN}__portal-button`}
        color="accent"
        icon="home"
        iconSize="lg"
        mode="frameless"
        onclick={this.toPortal}
      >
        <l10n group="common" tkey="header__to-portal" />
      </r-button>
    );
  }

  private renderActionOption(props: { item: HeaderAction }) {
    return (
      <span class={`${CN}__more-item`}>
        <icon size="md" type={props.item.icon} />
        <l10n class={`${CN}__item-text`} group="common" tkey={props.item.tkey} />
      </span>
    );
  }

  private renderSubMenu(actions: HeaderAction[], tkey: string, iconType: string) {
    return (
      <more-actions
        class={`${CN}__settings`}
        color="accent"
        options={actions}
        v-slots={{ option: this.renderActionOption }}
        onClick={this.doSettingsAction}
      >
        <span class={`${CN}__more-item`}>
          <icon size="md" type={iconType} />
          <l10n class={`${CN}__item-text`} group="common" tkey={tkey} />
          <icon type="drop-down" />
        </span>
      </more-actions>
    );
  }

  private replaceLocationToPortal(url: string) {
    const cn = detectCookieName();
    location.replace(`${url}?cn=${cn}`);
  }

  private toUploadPage() {
    this.$router.push({ name: 'Upload' });
  }
}
