import { defineComponent } from 'vue';

const CN = 'app-content';

export default defineComponent({
  props: {
    isExpandedNav: { type: Boolean, required: true },
  },
  setup(props) {
    return () => {
      const cn = {
        [CN]: true,
        [`${CN}--w-nav`]: props.isExpandedNav,
      };
      return <router-view class={cn} />;
    };
  },
});
