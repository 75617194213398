import { Teleport } from 'vue';
import { Vue } from 'vue-class-component';

import { RouteRecordRaw } from 'vue-router';

import AppNavItem from '@/components/app-nav-item/app-nav-item';
import { RavnurRouteConfig } from '@/entity-route-config';

const Portal: any = Teleport;

class Props {
  menus: RavnurRouteConfig[];
  routes: RouteRecordRaw[];
}

export default class BaseModule extends Vue.with(Props) {
  isMounted = false;

  mounted() {
    this.isMounted = true;
  }

  created() {
    this.routes.forEach((r) => this.$router.addRoute(r));
  }

  render() {
    if (!this.isMounted) {
      return;
    }
    return (
      <Portal to="#app-navigations">
        {this.menus.map((m) => (
          <AppNavItem key={m.tkey} item={m} />
        ))}
      </Portal>
    );
  }
}
