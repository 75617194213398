import { Checkable } from '@/transformers/checkable';
import { Attachment } from './Attachment';
import { Category } from './Category';
import { OrderableEntity, ThumbnailInfo, ThumbnailableEntity } from './Entity';
import { Job } from './Job';
import { LookupEntity } from './LookupEntity';
import { TagEntity } from './Tag';
import { UploadInfo } from './Upload';
import { User$Compact } from './User';

export enum Media$Type {
  AUDIO = 'Audio',
  VIDEO = 'Video',
  DOCUMENT = 'Document',

  PLAYLIST = 'Playlist', // virtual type. using just for filtering
  AUDIO_PLAYLIST = 'AudioPlaylist',
  VIDEO_PLAYLIST = 'VideoPlaylist',
}

export const REAL_MEDIA_TYPES: Media$Type[] = [
  Media$Type.VIDEO,
  Media$Type.AUDIO,
  Media$Type.DOCUMENT,
  Media$Type.AUDIO_PLAYLIST,
  Media$Type.VIDEO_PLAYLIST,
];

export const PLAYABLE_MEDIA_TYPES: Media$Type[] = [
  Media$Type.VIDEO,
  Media$Type.AUDIO,
  Media$Type.AUDIO_PLAYLIST,
  Media$Type.VIDEO_PLAYLIST,
];

export const SINGLE_MEDIA_TYPES: Media$Type[] = [
  Media$Type.VIDEO,
  Media$Type.AUDIO,
  Media$Type.DOCUMENT,
];

export const SINGLE_PLAYABLE_MEDIA_TYPES: Media$Type[] = [Media$Type.VIDEO, Media$Type.AUDIO];

export const PLAYLIST_MEDIA_TYPES: Media$Type[] = [
  Media$Type.AUDIO_PLAYLIST,
  Media$Type.VIDEO_PLAYLIST,
];

export enum Media$Privacy {
  PUBLIC = 'Public',
  UNLISTED = 'Unlisted',
  RESTRICTED = 'Restricted',
}

export const PRIVACIES = [Media$Privacy.PUBLIC, Media$Privacy.UNLISTED, Media$Privacy.RESTRICTED];
export const PUBLIC_APP_PRIVACIES = [Media$Privacy.PUBLIC, Media$Privacy.UNLISTED];

export const PRIVACY_LABELS: { [key in Media$Privacy]: string } = {
  [Media$Privacy.PUBLIC]: 'privacy__public',
  [Media$Privacy.UNLISTED]: 'privacy__unlisted',
  [Media$Privacy.RESTRICTED]: 'privacy__restricted',
};

export const PRIVACY_DESCS: { [key in Media$Privacy]: string } = {
  [Media$Privacy.PUBLIC]: 'privacy__public-desc',
  [Media$Privacy.UNLISTED]: 'privacy__unlisted-desc',
  [Media$Privacy.RESTRICTED]: 'privacy__restricted-desc',
};

export const PUBLOC_PRIVACY_DESCS: { [key in Media$Privacy]: string } = {
  [Media$Privacy.PUBLIC]: 'public-privacy__public-desc',
  [Media$Privacy.UNLISTED]: 'privacy__unlisted-desc',
  [Media$Privacy.RESTRICTED]: 'privacy__restricted-desc',
};

export enum Media$AvailabilityStatus {
  DELETED = 'Deleted',
  AVAILABLE = 'Available',
}

export enum Media$EnableStatus {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum Media$Availability {
  AVAILABLE = 'Available',
  DISABLED = 'Disabled',
  DELETED = 'Deleted',
  EXPIRED = 'Expired',
  PRERELEASED = 'Prereleased',
  NOT_REVIEWED = 'NotReviewed',
}

export enum Media$ExternalPage {
  NONE = 'None',
  PUBLIC = 'Public',
  // PROTECTED = 'Protected', // not a part of the First Release
}

export type OrderableCheckableMedia = Checkable<Media> & Omit<OrderableEntity, 'id'>;

export type Media = {
  title: string;
  owner: User$Compact;
  createdDate: string;
  views: number;
  privacy: Media$Privacy;
  type: Media$Type;
  availability: Media$Availability;
  fileExtension: Nullable<string>;
  itemsCount: Nullable<number>;
  isRetentionPolicy: boolean;
  isNotVod: boolean;
  playlistStreamingChannelIds: string[];
} & ThumbnailableEntity;

export type Media$Details = {
  description: string;
  duration: Nullable<number>;
  relatedLinks: Media$RelatedLink[];
  sites: string[];
  categories: Category[];
  tags: TagEntity[];
  allowedUsers: LookupEntity[];
  allowedGroups: LookupEntity[];
  enableStatus: Media$EnableStatus;
  availabilityStatus: Media$AvailabilityStatus;
  isAutoGenerateCaption: boolean;
  decryptedMediaId: string;

  languageId: string;

  isEnabledDownload: boolean;
  isEnabledEmbedding: boolean;
  isRetentionPolicy: boolean;
  allowExternalPage: Media$ExternalPage;

  optionalThumbnails: ThumbnailInfo[];

  allowComenting: boolean;
  allowRating: boolean;
  hideRelatedMedia: boolean;

  publishDate: Nullable<string>;
  expirationDate: Nullable<string>;

  groups: LookupEntity[];

  hasSubResources: boolean;
  fileUri: Nullable<string>;

  attachments: Attachment[] | null;
} & Media;

export type FeaturedVideo = OrderableEntity & Media;

export type FeaturedCarousel = {
  id: string;
  keyword: string;
  displayName: string;
  type: string;
  query: string;
  order: number;
  isEnabled: boolean;
  createdDate: string;
  playlistMediaId: string;
};

export enum AbuseReason {
  INCORRECT = '1',
  OUTDATED = '2',
  VIOLATES_COPYRIGHT = '3',
  INAPPROPRIATE = '4',
  BROKEN_RELATED_LINK = '5',
}

export type FlaggedMedia = {
  type: Media$Type;
  title: string;
  submittedBy: User$Compact;
  submittedOn: string;
  reason: AbuseReason;
  comment: string;
  enableStatus: Media$EnableStatus;
} & ThumbnailableEntity;

export type AbuseMedia = {
  reportId: string;
} & FlaggedMedia;

export type Media$RelatedLink = {
  path: string;
  name: string;
};

export type Media$Version = {
  url: string;
  isActive: boolean;
  createdDate: Nullable<string>;
  format: string;
  size: string;
  job: Nullable<Job>;
  uploadInfo?: Nullable<UploadInfo>;
  isNotVOD: boolean;
} & Entity;

export enum Media$QualityType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export type Media$Quality = {
  format: string;
  quality: string;
  size: string;
  src: string;
  type: Media$QualityType;
};

export type ClipInfo = {
  start: number;
  duration: number;
  title: string;
  description: string;
  tags: TagEntity[];
};

export type TrimInfo = {
  start: number;
  duration: number;
  title: string;
};

export function isMediaEntity(e: Entity): e is Media {
  const type = (e as Media).type;
  return type ? REAL_MEDIA_TYPES.some((t) => t === type) : false;
}
