import OutsideClickListener from '@ravnur/core/components/outside-click-listener/outside-click-listener';
import { $Types } from '@ravnur/core/typings/tsx';
import { setup, Vue } from 'vue-class-component';
import useSecurityStore from '@/store/security';
import { RavnurApplication } from '@/types/Auth';
import { ApplicationTypes } from '@ravnur/shared/types/Application';

import './application-chosen.scss';

const CN = 'application-chosen';

type Emits = {
  onRedirect: (value: string) => void;
};

export default class ApplicationChosen extends Vue {
  declare $props: $Types<unknown, Emits>;
  private security = setup(() => useSecurityStore());

  private isOpen = false;

  private get isSingleApplication() {
    return this.applications.length === 1;
  }

  private get currentApplication() {
    return this.security.currentApplication;
  }

  private get applications() {
    return this.security.applications.filter(
      (app) => app.applicationType !== ApplicationTypes.MY_ACCOUNT
    );
  }

  render() {
    const { isOpen, currentApplication } = this;
    return (
      <div class={CN}>
        {this.isSingleApplication ? (
          currentApplication ? (
            this.renderApplicationLogo(currentApplication)
          ) : (
            ''
          )
        ) : (
          <>
            <button class={`${CN}__toggler`} type="button" onClick={this.toggle}>
              {currentApplication ? this.renderApplicationLogo(currentApplication) : ''}
              <icon class={`${CN}__icon`} size="md" type="drop-down" />
            </button>
            {isOpen ? this.renderOptions() : null}
          </>
        )}
      </div>
    );
  }

  private renderOptions() {
    return (
      <OutsideClickListener onClick={this.close}>
        <ul class={`${CN}__list`}>{this.applications.map(this.renderApplicationItem)}</ul>
      </OutsideClickListener>
    );
  }

  private renderApplicationItem(app: RavnurApplication) {
    return (
      <li class={`${CN}__item`} title={app.name} onClick={() => this.select(app)}>
        {this.renderApplicationLogo(app)}
      </li>
    );
  }

  private renderApplicationLogo(app: RavnurApplication) {
    return (
      <span class={`${CN}__application`} title={app.name}>
        <img alt="Logo" class={`${CN}__logo`} src={app.logoUrl} />
      </span>
    );
  }

  private toggle() {
    this.isOpen = !this.isOpen;
  }

  private close() {
    this.isOpen = false;
  }

  private select({ adminUrl }: RavnurApplication) {
    this.close();
    this.$emit('redirect', adminUrl);
  }
}
