export enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  LOG = 'log',
  DEBUG = 'debug',
}

export default class Logger {
  constructor(name: string) {
    Object.defineProperty(this, '_name', {
      get: () => name,
    });
  }

  protected get _name(): string {
    throw new Error('Unsupported operation exception');
  }

  public debug(...args: unknown[]) {
    _push(LogLevel.DEBUG, this._name, args);
  }

  public log(...args: unknown[]) {
    _push(LogLevel.LOG, this._name, args);
  }

  public warn(...args: unknown[]) {
    _push(LogLevel.WARN, this._name, args);
  }

  public error(...args: unknown[]) {
    _push(LogLevel.ERROR, this._name, args);
  }
}

function _push(level: LogLevel, loggerName: string, args: unknown[]) {
  const texts: string[] = toConsole(loggerName, level, args);
  console[level](...texts);
}

function toConsole(loggerName: string, level: LogLevel, msgs: unknown[]): string[] {
  const messages: string[] = msgs.map(_stringify).filter((_) => _);
  return [loggerName, level.toUpperCase(), ...messages];
}

function _stringify(o: unknown): string {
  if (o instanceof Error) {
    return o.message;
  }
  if (o instanceof Object) {
    return JSON.stringify(o);
  }

  if (typeof o === 'string') {
    return o;
  }

  if (typeof o === 'number' || typeof o === 'boolean') {
    return o.toString();
  }

  return '';
}
