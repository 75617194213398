import {
  WebAuthData,
  RegistrationData,
  RestorePasswordInfo,
  ChangePasswordData,
} from '@ravnur/auth/types/AuthService';

import wait from './operation-helper';

import { get, post, remove } from '@/helpers/http';
import { AuthResponse } from '@/types/Auth';
import { OperationEntity } from '@/types/Operation';
import { AuthSettings } from '@ravnur/auth/types/AuthSettings';
import axios from 'axios';
import { CHANGE_PASSWORD_URL } from '@/constants';

if (process.env.VUE_APP_MOCK_API === 'yes') {
  (function _bootstrap() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const adapert = require('../mocks/index').default;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fnc = require('../mocks/auth').default;
    fnc(adapert);
  })();
}

export default class AuthRepository {
  public check(): Promise<AuthResponse> {
    return get(`/auth`);
  }

  public async auth(data: WebAuthData): Promise<AuthResponse> {
    return post('/auth', data);
  }

  public logout(): Promise<AuthResponse> {
    return remove(`/auth`);
  }

  public registration(data: RegistrationData): Promise<unknown> {
    return post(`/registration`, data).then(wait);
  }

  public restorePassword(email: string): Promise<unknown> {
    return post(`/restore-password`, { email }).then(wait);
  }

  public checkRestoreToken(token: string): Promise<RestorePasswordInfo> {
    return axios
      .get(`${CHANGE_PASSWORD_URL}/check-token`, { params: { token } })
      .then((r) => r.data)
      .catch((e) => Promise.reject({ ...e.response.data, status: e.response.status }));
  }

  public changePassword(data: ChangePasswordData): Promise<unknown> {
    return axios
      .post(`${CHANGE_PASSWORD_URL}/change-password`, data)
      .then((r) => r.data)
      .then(wait)
      .catch((e) => Promise.reject({ ...e.response.data, status: e.response.status }));
  }

  public async verify(data: WebAuthData): Promise<{ token: string }> {
    const op: OperationEntity<{ token: string }> = await post(`/verify`, data);
    const operation = await wait<{ token: string }>(op);
    return operation.data;
  }

  public activationInfo(): Promise<{ logoPath: string }> {
    return get(`/activation-info`);
  }

  public getAuthSettings(): Promise<AuthSettings> {
    return get('/auth/settings').catch((e) => Promise.reject(e.response.data));
  }
}
