import { defineComponent, reactive } from 'vue';

import BaseModule from '../base-module';

import { METADATA, ROUTES } from './metadata-routes';

export default defineComponent({
  setup() {
    return () => <BaseModule key="metadata" menus={[reactive(METADATA)]} routes={ROUTES} />;
  },
});
