import { defineComponent } from 'vue';

import useRootStore from '@/store/root';
import { Breadcrumb } from '@/types/Breadcrumb';

import './breadcrumbs.scss';
const CN = 'breadcrumbs';

export default defineComponent({
  props: {
    isExpandedNav: { type: Boolean, required: true },
  },
  setup(props) {
    const store = useRootStore();

    const renderItem = (breadcrumb: Breadcrumb) => {
      const child = breadcrumb.to ? (
        <router-link to={breadcrumb.to}>{breadcrumb.label}</router-link>
      ) : (
        <span aria-current="page">{breadcrumb.label}</span>
      );
      return <li class={`${CN}__item`}>{child}</li>;
    };

    return () => {
      if (!store.breadcrumbs.length) {
        return;
      }
      const cn = {
        [CN]: true,
        [`${CN}--w-nav`]: props.isExpandedNav,
      };
      return (
        <nav aria-label="Breadcrumb">
          <ol class={cn}>{store.breadcrumbs.map(renderItem)}</ol>
        </nav>
      );
    };
  },
});
