import curry2, { CurriedFunction1 } from './curry2';
import isNotNil from './isNotNil';

interface FilterFnc {
  <T>(iteratees: Predicate<T>): CurriedFunction1<T[], T[]>;
  <T>(iteratees: Predicate<T>, arr: T[]): T[];
}

const filter: FilterFnc = curry2(_filter);
export default filter;

export const existings = filter(isNotNil) as <T>(arr: Array<T | null | undefined>) => T[];

function _filter<T>(iteratees: Predicate<T>, arr: T[]): T[] {
  return arr.filter(iteratees);
}
