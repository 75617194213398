import { factory } from './resumable';

import Resumable from '@/libs/Resumable';
import MediaRepository from '@/repositories/media-repository';
import { RootStore } from '@/store/root';
import { UploadStore } from '@/store/upload';
import { UploadInfo } from '@/types/Upload';

const repository = new MediaRepository();

export default class UploadService {
  public resumable: Resumable;

  constructor(private store: UploadStore, root: RootStore) {
    this.resumable = factory(store, root);
  }

  public add(
    file: File,
    payload: { mediaId: string; keepSubResources: boolean; autoStartProcess: boolean }
  ) {
    this.resumable.addFile(file, payload);
  }

  public assignDrop(el: HTMLElement) {
    this.resumable.assignDrop(el);
  }

  public assignBrowse(el: HTMLElement) {
    this.resumable.assignBrowse(el, false);
  }

  public unassign(el: HTMLElement) {
    this.resumable.unAssignDrop(el);
  }

  public pause({ uid }: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    if (file) {
      file.pause();
    }
  }

  public resume({ uid }: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    if (file) {
      file.pause(false);
      file.upload();
    }
  }

  public retry({ uid }: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    if (file) {
      file.retry();
    }
  }

  async cancel(info: UploadInfo, isVersion = false) {
    const file = this.resumable.getFromUniqueIdentifier(info.uid);
    if (file) {
      file.cancel();
    }
    if (isVersion) {
      await repository.undoUploading(info.mediaId, {
        params: { identifier: info.uid, fileName: file?.fileName },
      });
    } else {
      await repository.cancelUpload([{ id: info.mediaId }]);
    }
    this.store.stop(info);
  }

  public getFileName(uid: string) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    return (file && file.fileName) || '';
  }

  public addAllowedFormats(formats: string[]) {
    this.resumable.opts.fileType = formats;
  }
}
