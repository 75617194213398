import { RouteRecordRaw } from 'vue-router';

import { RavnurRouteLeafConfig, EntityRouteKey } from '@/entity-route-config';

export const ABUSE: RavnurRouteLeafConfig = {
  tkey: 'menu__abuse-media',
  allowedRouteRestriction: 'isManageAbuseReportsAllowed',
  enabledRouteRestriction: 'isManageAbuseReportsEnabled',
  icon: 'report',
  order: 9,
  routes: {
    [EntityRouteKey.LIST]: 'AbuseMedia',
  },
};

export const ABUSE_ROUTES: RouteRecordRaw[] = [
  {
    path: '/abuse',
    name: 'AbuseMedia',
    component: () => import('./pages/abuse-media'),
    meta: { translations: ['media', 'abuse-media'], config: ABUSE },
  },
];
