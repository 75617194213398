import { showErrorNotification } from '@ravnur/notifications/service';
import { Options, setup, Vue } from 'vue-class-component';

import MediaUploadCard from '@/components/media/media-upload-card/media-upload-card';
import MediaUploadSummary from '@/components/media/media-upload-summary/media-upload-summary';
import ModalService from '@/helpers/modal.service';
import UploadService from '@/helpers/upload-service';
import useRootStore from '@/store/root';
import useUploadStore from '@/store/upload';

import { UploadInfo } from '@/types/Upload';

import './upload.scss';

const CN = 'upload';

@Options({
  inject: {
    uploadService: 'UPLOAD_SERVICE',
  },
})
export default class Upload extends Vue {
  declare uploadService: UploadService;

  private upload = setup(() => useUploadStore());
  private root = setup(() => useRootStore());

  get items() {
    return this.upload.list;
  }

  render() {
    return (
      <div ref="dropTarget" class={CN}>
        <card
          class={`${CN}__card`}
          title={this.$t('common', 'header__upload')}
          v-show={!this.items.length}
        >
          <div class={`${CN}__title`}>
            <l10n group="media" tkey="upload-card__drag-and-drop" />
          </div>
          <div ref="browseTarget" class={`${CN}__input`}>
            <icon class={`${CN}__icon`} type="upload" />
          </div>
        </card>

        <MediaUploadSummary class={`${CN}__card`} v-show={this.items.length} />
        {this.items.map((item) => (
          <MediaUploadCard
            key={item.uid}
            class={`${CN}__card`}
            item={item}
            onChange={this.onChange}
          />
        ))}
      </div>
    );
  }

  private async onChange({ state, item }: { state: string; item: UploadInfo }) {
    if (state === 'cancel') await this.cancelUploading(item);
    if (state === 'pause') this.uploadService.pause(item);
    if (state === 'resume') this.uploadService.resume(item);
    if (state === 'retry') this.uploadService.retry(item);
    if (state === 'failed') this.handleError(item);
    if (state === 'delete') this.deleteUploadedData(item);
  }

  private handleError(item: UploadInfo) {
    showErrorNotification(
      this.$t('common', 'notification_center__failed_upload', { mediaId: item.mediaId })
    );
  }

  private deleteUploadedData(item: UploadInfo) {
    try {
      this.upload.stop(item);
    } catch (e) {
      this.$processException(e);
    }
  }

  private async cancelUploading(item: UploadInfo) {
    this.uploadService.pause(item);

    const msg = this.$t('media', 'download__abort-confirmer');
    const confirmText = this.$t('media', 'download__delete-btn');
    const flag = await ModalService.confirm(msg, { confirmText }).catch(() => false);

    if (!flag) {
      this.uploadService.resume(item);
      return;
    }

    try {
      await this.uploadService.cancel(item);
    } catch (e) {
      this.$processException(e);
      this.uploadService.pause(item);
    }
  }

  mounted() {
    this.$nextTick(() => {
      const { dropTarget, browseTarget } = this.$refs;

      this.uploadService.addAllowedFormats(this.root.allAllowedFormats);

      if (dropTarget && dropTarget instanceof HTMLElement) {
        this.uploadService.assignDrop(dropTarget);
      }

      if (browseTarget && browseTarget instanceof HTMLElement) {
        this.uploadService.assignBrowse(browseTarget);
      }
    });
  }
}
