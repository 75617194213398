import { defineComponent } from 'vue';

import BaseModule from '../base-module';

import { ABUSE, ABUSE_ROUTES } from './abuse-routes';

export default defineComponent({
  setup() {
    return () => {
      return <BaseModule key="abuse" menus={[ABUSE]} routes={ABUSE_ROUTES} />;
    };
  },
});
