import axios, { AxiosRequestConfig } from 'axios';

import { BASE_API_PREFIX } from '@/constants';

export function get(path: string, params: unknown = {}) {
  return axios
    .get(`${BASE_API_PREFIX}${path}`, { params })
    .then((r) => r.data)
    .catch((e) => Promise.reject({ ...e.response.data, status: e.response.status }));
}

export function post(path: string, data: unknown, params: Dictionary<unknown> = {}) {
  return axios
    .post(`${BASE_API_PREFIX}${path}`, data, { params })
    .then((r) => r.data)
    .catch((e) => Promise.reject({ ...e.response.data, status: e.response.status }));
}

export function put(path: string, data: unknown, params: Dictionary<unknown> = {}) {
  return axios
    .put(`${BASE_API_PREFIX}${path}`, data, { params })
    .then((r) => r.data)
    .catch((e) => Promise.reject({ ...e.response.data, status: e.response.status }));
}

export function remove(path: string, options: AxiosRequestConfig = {}) {
  return axios
    .delete(`${BASE_API_PREFIX}${path}`, options)
    .then((r) => r.data)
    .catch((e) => Promise.reject({ ...e.response.data, status: e.response.status }));
}
