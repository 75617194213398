import keyBy from '@ravnur/nanoutils/keyBy';
import { defineStore } from 'pinia';
import { UploadInfo, UploadProgressEvent, UploadStatus } from '@/types/Upload';

const keyByMediaId: (list: UploadInfo[]) => Dictionary<UploadInfo> = keyBy('mediaId');
const keyByUid: (list: UploadInfo[]) => Dictionary<UploadInfo> = keyBy('uid');
type State = {
  list: UploadInfo[];
};

const useUploadStore = defineStore({
  id: 'upload',
  state: (): State => ({ list: [] }),
  getters: {
    cacheByMediaId: (state) => keyByMediaId(state.list),
    cacheByUid: (state) => keyByUid(state.list),
    isProcessing: (state) =>
      state.list.some((item) =>
        [UploadStatus.PROCESSING, UploadStatus.INITIALIZED].includes(item.status)
      ),
  },
  actions: {
    start(info: UploadInfo) {
      this.list.unshift(info);
    },

    addMediaId({ uid, mediaId }: { uid: string; mediaId: string }) {
      const index = this.list.findIndex((info: UploadInfo) => info.uid === uid);

      if (index === -1) return;

      this.list[index].mediaId = mediaId;
    },

    handleProgressEvent({ uid, progress, status, isPaused }: UploadProgressEvent) {
      const [info] = this.list.filter((i) => i.uid === uid);
      if (!info) {
        return;
      }
      info.progress = progress;
      info.status = status;
      info.isPaused = isPaused;
    },

    stop({ uid }: UploadInfo) {
      this.list = this.list.filter((i) => i.uid !== uid);
    },

    stopByMediaId(mediaId: string) {
      this.list = this.list.filter((i) => i.mediaId !== mediaId);
    },

    clear() {
      this.list = [];
    },
  },
});

export default useUploadStore;
export type UploadStore = ReturnType<typeof useUploadStore>;
