import { createThesaurusService } from './thesaurus-service';

import { Thesaurus } from '@/repositories/thesaurus-repository';
import { Language } from '@/types/Language';
import { Role } from '@/types/Role';

export const whitelistedDomainsService = createThesaurusService<Entity>(
  `/${Thesaurus.WHITELISTED_DOMAIN}`
);
export const rolesService = createThesaurusService<Role>(`/${Thesaurus.ROLES}`);
export const languagesService = createThesaurusService<Language>(`/${Thesaurus.LANGUAGE}`);
