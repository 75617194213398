import { Media$Type } from '@/types/Media';

export enum Job$Type {
  AUDIO_INDEXING = 'AudioIndexing',
  AUDIO_TRANSCODING = 'AudioTranscoding',
  VIDEO_TRANSCODING = 'VideoTranscoding',

  // FIXME: Below are old job types, probably whe should delete them
  TRANSCODING = 'transcoding',
  TRIMMING = 'trimming',
  CC_GENERATION = 'ccGeneration',
  VIDEO_INDEXING = 'videoIndexing',
  RETRANSCODING = 'retranscoding',
  UPLOAD_NEW_VERSION = 'newVersionUpload',
}

export enum Job$Reason {
  UNKNOWN = 'Unknown',
  INITIAL_UPLOAD = 'InitialUpload',
  LIVE_STREAM = 'LiveStream',
  NEW_VERSION_UPLOAD = 'NewVersionUpload',
  RETRANSCODING = 'Retranscoding',
  CHANGE_VERSION = 'ChangeVersion',
  CLIPPING = 'Clipping',
  TRIMMING = 'Trimming',
  CC_GENERATION = 'CcGeneration',
}

export enum Job$Status {
  QUEUED = 0,
  RUNNING = 1,
  SUCCESS = 2,
  ERROR = 3,
  CANCELLING = 23,
  CANCELED = 24,
}

export type Job$Common = {
  mediaId: string;
  state: Job$Status;
  createdBy: string;
  progress: number;
  type: Job$Type;
  mediaTitle: Nullable<string>;
  mediaType: Media$Type;
  submittedOn: string;
  startedOn: Nullable<string>;
  reason: Job$Reason;
  completedOn: Nullable<string>;
  initiator: {
    id: string;
    displayName: string;
  };
} & Entity;

export type Job$Trimming = {
  type: Job$Type.TRIMMING;
  data: {
    in: number;
    out: number;
  };
} & Job$Common;

export type Job = Job$Common | Job$Trimming;

export function isRunningJob(job: Job): boolean {
  return job.state === Job$Status.RUNNING || job.state === Job$Status.QUEUED;
}
