import { defineComponent, reactive } from 'vue';

import BaseModule from '../base-module';

import { ROUTES, LIVE } from './live-routes';

import useSecurityStore from '@/store/security';

export default defineComponent({
  setup() {
    const security = useSecurityStore();

    return () => {
      if (security.isLiveStreamsAvailable) {
        return <BaseModule key="live" menus={[reactive(LIVE)]} routes={ROUTES} />;
      }
    };
  },
});
