import { refreshCookieName } from '@ravnur/auth/helpers/refresh-cookie-name';
import Login from '@ravnur/auth/pages/login-page/login-page';
import { AuthService } from '@ravnur/auth/types/AuthService';
import { COMPONENT_CTX_PROVIDER_KEY } from '@ravnur/core/constants';
import { TranslationLocale } from '@ravnur/l10n/$t';
import * as l10n from '@ravnur/l10n/service';
import { defineComponent, nextTick, PropType, provide } from 'vue';

import UnsavedChecker from './unsaved-checker';

import { BASE_API_PREFIX } from '@/constants';
import UploadService from '@/helpers/upload-service';
import ApplicationPermissionsChecker from '@/pages/admin/application-permissions-checker';
import PortalLayout from '@/pages/admin/portal-layout';
import AuthRepository from '@/repositories/auth-repository';
import wait from '@/repositories/operation-helper';
import useRootStore from '@/store/root';
import useSecurityStore from '@/store/security';
import useUploadStore from '@/store/upload';
import { AuthResponse } from '@/types/Auth';
import { Operation$Status } from '@/types/Operation';
import { $processException } from '@/helpers/error-handler';
import enUS from '@/translations/en-US.json';
import { languagesService } from '@/modules/common-services';

import './ravnur-admin.scss';

const CN = 'ravnur-admin';

const authService: AuthService<AuthResponse> = {
  repository: new AuthRepository(),
  providerPathGenerator,
  operationWaiter,
};

export default defineComponent({
  name: 'admin-app',
  props: {
    onReady: { type: Function as PropType<() => void>, required: true },
    registerAppInsights: { type: Function as PropType<(id: string) => void> },
    registerGTM: { type: Function as PropType<(id: string) => void> },
  },
  setup(props) {
    const security = useSecurityStore();
    const store = useRootStore();
    const upload = useUploadStore();

    provide('LIST_BUILDER_PAGE_SIZE', 15);
    provide(COMPONENT_CTX_PROVIDER_KEY, { disabled: false });
    provide('UPLOAD_SERVICE', new UploadService(upload, store));

    const handleError = (e: Record<string, any>) => {
      if (e.status !== 401) {
        $processException(e);
      }
    };

    const handleAuthEvent = (resp: AuthResponse) => {
      security.successAuthenticated(resp.user);
      security.setApplications(resp.applications);
      security.setPermissions(resp.userPermissions);
      security.setPublicDomain(resp.publicDomain);
      security.setPortalSettings(resp.portalFeatures);
      store.setPortalConfiguration(resp.portalConfiguration);
      l10n.init(generateLocaleInitInfo(resp), { [l10n.AVAILABLE_LOCALES.ENGLISH]: enUS });
      refreshCookieName(resp.cookieName);
      languagesService.load();

      const { analytics } = resp.portalConfiguration;

      // if (analytics.appInsightId && props.registerAppInsights) {
      //   props.registerAppInsights(analytics.appInsightId);
      // }

      if (analytics.gtmID) {
        props.registerGTM?.(analytics.gtmID);
      }

      // Temporary hardcode
      if (props.registerAppInsights) {
        props.registerAppInsights(
          'InstrumentationKey=317ff99b-5d2e-4367-8de5-161188a18530;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/'
        );
      }

      document.title = security.currentApplication?.name || 'Admin portal';

      // RMP-2999: Resolve route only when all permissions fetched and
      // modules mounted (dynamic routes added)
      // See cms/src/main.ts onRaedy method for details.
      nextTick(() => props.onReady?.());
    };

    return () => {
      if (security.isAuth) {
        return (
          <ApplicationPermissionsChecker>
            <UnsavedChecker>
              <PortalLayout class={CN} />
            </UnsavedChecker>
          </ApplicationPermissionsChecker>
        );
      }
      return (
        <Login class={CN} service={authService} onAuth={handleAuthEvent} onError={handleError} />
      );
    };
  },
});

function providerPathGenerator(loginEndpointPath: string): string {
  const url = encodeURIComponent(window.location.href);

  return `${BASE_API_PREFIX}/${loginEndpointPath}?url=${url}`;
}

async function operationWaiter(operationId: string): Promise<void> {
  await wait({
    data: {},
    entityId: null,
    errorReason: [],
    operationId,
    state: Operation$Status.IN_PROGRESS,
  });
}

function generateLocaleInitInfo(resp: AuthResponse): TranslationLocale {
  return {
    supportedLocales: resp.supportedLocales,
    translationHash: resp.translationHash,
    userLang: resp.user.languageId,
  };
}
