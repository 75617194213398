import IntersectionObserverComponent from '@ravnur/core/components/intersection-observer/intersection-observer';
import { $Props } from '@ravnur/core/typings/tsx';
import { renderSlot } from 'vue';
import { Options, Vue } from 'vue-class-component';

import { ThumbnailableEntity as TE } from '@/types/Entity';
import { isMediaEntity } from '@/types/Media';

import './thumbnail.scss';

class Props {
  entity!: TE;
}

export type Thumbnail$Props = $Props<Props> & Partial<HTMLDivElement>;

const CN = 'thumbnail';

@Options({
  watch: {
    'entity.thumbnail': {
      immediate: true,
      handler(this: Thumbnail) {
        this.handleEntityChanged();
      },
    },
  },
})
export default class Thumbnail extends Vue.with(Props) {
  public isLoaded = false;
  public isVisible = false;

  private get info() {
    return this.entity.thumbnail;
  }

  private get url(): string {
    return (this.isVisible && this.info?.url) || '';
  }

  private get icon(): string {
    const { entity } = this;
    if (isMediaEntity(entity)) {
      return entity.type.toLowerCase();
    }
    return 'group';
  }

  protected handleEntityChanged() {
    this.isLoaded = false;
    this.isVisible = false;
  }

  render() {
    const { isLoaded, url, isVisible, $attrs } = this;
    const cn = { [CN]: true, [`${CN}--default`]: !isLoaded };
    return (
      <IntersectionObserverComponent enable={!isVisible} onIntersected={this.setVisible}>
        <div class={cn} {...$attrs}>
          {url ? (
            <img
              alt="Thumbnail"
              class={`${CN}__img`}
              draggable={false}
              src={url}
              onLoad={this.onload}
            />
          ) : null}
          {this.renderIcon()}
          {renderSlot(this.$slots, 'default')}
        </div>
      </IntersectionObserverComponent>
    );
  }

  private renderIcon() {
    const { entity, icon: iconType } = this;
    if (isMediaEntity(entity)) {
      return <media-icon class={`${CN}__icon-preview`} entity={entity} />;
    }
    return <icon class={`${CN}__icon-preview`} type={iconType} />;
  }

  private onload() {
    this.isLoaded = true;
  }

  private setVisible() {
    this.isVisible = true;
  }
}
