import { $Props } from '@ravnur/core/typings/tsx';
import { RButton$Props } from '@ravnur/core/ui-kit/button/button';
import { renderSlot } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

import './download-button.scss';

const CN = 'download-button';

type Color = 'accent' | 'primary' | 'grey' | 'white' | 'black' | 'danger';
type Mode = 'standard' | 'secondary' | 'frameless' | 'flat' | 'borderless';

class Props {
  disabled = prop({ default: false });
  src!: string;
  title!: string;
  name = prop({ default: '' });
  color = prop<Color>({ default: 'primary' });
  mode = prop<Mode>({ default: 'standard' });
  icon = prop({ default: 'download' });
}

export type DownloadButton$Props = $Props<Props> & RButton$Props;

@Options({
  name: 'download-button',
})
export default class DownloadButton extends Vue.with(Props) {
  public render() {
    const { color, mode, icon, title, $slots } = this;
    return (
      <div class={CN}>
        <r-button
          class={`${CN}__btn`}
          color={color}
          disabled={this.disabled}
          icon={icon}
          mode={mode}
          onclick={this.handleClickEvent}
          title={title}
        >
          {renderSlot($slots, 'default')}
        </r-button>
        <a ref="link" class={`${CN}__link`} href="#" />
      </div>
    );
  }

  private async handleClickEvent() {
    const { link } = this.$refs;
    if (!(link instanceof HTMLAnchorElement) || !this.src) {
      return;
    }
    link.href = this.src;
    link.download = this.name;
    link.click();
  }
}
