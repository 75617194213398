/* eslint-disable @typescript-eslint/no-var-requires */
import BaseRepository, { PagedListResponse } from './base-repository';

import { SortType } from '@/transformers/sort-by';

interface ThesaurusQueryParams {
  sortField: string;
  sortType: SortType;
  offset: number;
  count: number;
}

export enum Thesaurus {
  LANGUAGE = 'language',
  ROLES = 'role',
  WHITELISTED_DOMAIN = 'whitelisted_email_domain',
}

if (process.env.VUE_APP_MOCK_API === 'yes') {
  (function _bootstrap() {
    const adapert = require('../mocks/index').default;

    require('../mocks/languages').default(adapert);
    require('../mocks/roles').default(adapert);
    require('../mocks/whitelisted-domain').default(adapert);
  })();
}

type LR<E> = PagedListResponse<E>;
export default class ThesaurusRepository<E extends Entity> extends BaseRepository<
  E,
  ThesaurusQueryParams,
  LR<E>
> {
  constructor(thesaurus: Thesaurus) {
    super(`/${thesaurus}`);
  }
}
