import { $Props } from '@ravnur/core/typings/tsx';
import { Vue, Options } from 'vue-class-component';

import { Media } from '@/types/Media';

const CN = 'media-icon';

const EXTENSIONS: Dictionary<string> = {
  '.pdf': 'pdf',
  '.xls': 'ms-excel',
  '.xlsx': 'ms-excel',
  '.doc': 'ms-word',
  '.docx': 'ms-word',
  '.ppt': 'ms-ppt',
  '.pptx': 'ms-ppt',
};

class Props {
  entity!: Media;
}

export type MediaIcon$Props = $Props<Props>;

@Options({
  name: 'media-icon',
})
export default class MediaIcon extends Vue.with(Props) {
  render() {
    const {
      entity: { type: entityType, fileExtension },
    } = this;
    const type = (fileExtension && EXTENSIONS[fileExtension]) || entityType.toLowerCase();
    return <icon class={CN} type={type} />;
  }
}
