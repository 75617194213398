import { $Props } from '@ravnur/core/typings/tsx';
import { Watch } from '@ravnur/decorators';
import { renderSlot } from 'vue';
import { Vue } from 'vue-class-component';

import './grid.scss';

const CN = 'grid';

class Props {
  count: number;
}

export type Grid$Props = $Props<Props>;

export default class Grid extends Vue.with(Props) {
  private get indexes(): number[] {
    return new Array(this.count).fill('').map((_, idx) => idx + 1);
  }

  @Watch('count')
  protected handleCountChanged() {
    const { $el } = this;
    if ($el instanceof HTMLDivElement) {
      $el.style.setProperty('--count', `${this.count}`);
      const gutter = ((this.count - 1) / this.count).toFixed(2);
      $el.style.setProperty('--gutter', `${gutter}rem`);
    }
  }

  mounted() {
    this.handleCountChanged();
  }

  render() {
    return <div class={CN}>{this.indexes.map(this.renderItem)}</div>;
  }

  renderItem(idx: number) {
    return (
      <column key={idx} class={`${CN}__item`}>
        {renderSlot(this.$slots, `${idx}`)}
      </column>
    );
  }
}
