import $t from '@ravnur/l10n/$t';
import { showErrorNotification } from '@ravnur/notifications/service';
import { Plugin } from 'vue';

import { EntityRouteKey, isRouteLeafConfig } from '@/entity-route-config';
import Logger from '@/logger';

import { convertFromError } from '@/transformers/notifications';

export const BaseAdminPlugin: Plugin = {
  install: function install(app) {
    app.mixin({
      beforeCreate() {
        this.$options.computed = this.$options.computed || {};
        this.$options.computed.$t = () => $t;
        this.$options.computed.$logger = () => new Logger(this.$options.name || 'Component');

        if (!this.$options.methods) {
          return;
        }

        this.$options.methods.$processException = function (e: Dictionary<unknown>) {
          convertFromError(e).forEach(showErrorNotification);
        };

        this.$options.methods.$moveToPageView = function $moveToPageView(
          key: EntityRouteKey,
          params: Dictionary<string> = {}
        ) {
          const { config } = this.$route.meta;
          if (isRouteLeafConfig(config)) {
            this.$router.push({ name: config.routes[key], params });
          }
        };
      },
    });
  },
};
