import { $Props } from '@ravnur/core/typings/tsx';

import { Vue, Options, setup } from 'vue-class-component';

import useJobsStore from '@/store/jobs';
import { Job, Job$Status } from '@/types/Job';

import './media-job-progressbar.scss';

const CN = 'media-job-progressbar';

class Props {
  item: Nullable<Job>;
  mediaId?: string;
  loading?: boolean;
}

class Emits {
  onCancel: () => Promise<void> | null;
  onRetry: () => Promise<void> | null;
  onDelete: () => Promise<void> | null;
}

@Options({
  emits: ['cancel'],
})
export default class MediaJobProgressbar extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;

  private jobs = setup(() => useJobsStore());

  get log() {
    if (!this.mediaId) return null;

    return this.jobs.log[this.mediaId];
  }

  get job(): Job | null {
    return this.item || this.log;
  }

  get isFailed() {
    return this.job?.state === Job$Status.ERROR;
  }

  get isQueued() {
    return this.job?.state === Job$Status.QUEUED;
  }

  get isCanceled() {
    return this.job?.state === Job$Status.CANCELED;
  }

  get isFinished() {
    return (
      !this.item &&
      (this.job?.state === Job$Status.RUNNING || this.job?.state === Job$Status.SUCCESS)
    );
  }

  get progress(): number {
    if (this.item?.progress) return this.item.progress;
    if (this.isFinished || this.isCanceled) return 100;
    if (this.isFailed) return this.job?.progress || 0;

    return 0;
  }

  get label() {
    if (this.isCanceled) return this.$t('media', 'processing_canceled');

    if (this.isFinished) return this.$t('media', 'processing_completed');

    if (this.isFailed) {
      return this.$t('media', 'processing_failed', {
        progress: this.progress.toFixed(2),
      });
    }

    if (this.isQueued) return this.$t('media', 'processing_queued');

    return this.$t('media', 'download__processing', {
      progress: this.progress.toFixed(2),
    });
  }

  render() {
    return (
      <progressbar
        class={[
          `${CN}`,
          {
            [`${CN}__failed`]: this.isFailed || this.isCanceled,
            [`${CN}__canceled`]: this.isCanceled,
          },
        ]}
        label={this.label}
        loading={this.loading}
        value={this.progress}
      >
        {!this.loading && this.job && (
          <div class={`${CN}__actions`}>
            {(this.isFailed || this.isCanceled) && (
              <>
                <r-button
                  icon="refresh"
                  mode="frameless"
                  onclick={() => this.$emit('retry')}
                  title={this.$t('media', 'download__retry')}
                />
                <r-button
                  icon="delete"
                  mode="frameless"
                  title={this.$t('common', 'action__delete')}
                  onClick={() => this.$emit('delete')}
                />
              </>
            )}
            {!this.isFailed && !this.isFinished && !this.isCanceled && (
              <r-button
                icon="cancel"
                mode="frameless"
                title={this.$t('common', 'action__cancel')}
                onClick={() => this.$emit('cancel')}
              />
            )}
          </div>
        )}
      </progressbar>
    );
  }
}
