import { Vue, Options, setup } from 'vue-class-component';

import UploadService from '@/helpers/upload-service';
import useRootStore from '@/store/root';
import useUploadStore from '@/store/upload';
import { UploadStatus } from '@/types/Upload';

import './media-upload-summary.scss';

const CN = 'media-upload-summary';

@Options({
  inject: {
    uploadService: 'UPLOAD_SERVICE',
  },
})
export default class MediaUploadSummary extends Vue {
  declare uploadService: UploadService;

  private upload = setup(() => useUploadStore());
  private root = setup(() => useRootStore());

  get completed() {
    return this.upload.list.filter((item) => item.status === UploadStatus.FINISHED).length;
  }

  get inProgress() {
    return this.upload.list.filter((item) => item.status === UploadStatus.PROCESSING).length;
  }

  get queued() {
    return this.upload.list.filter((item) => item.status === UploadStatus.INITIALIZED).length;
  }

  get failed() {
    return this.upload.list.filter((item) => item.status === UploadStatus.FAILED).length;
  }

  get renderAddFileButton() {
    return (
      <div ref="addFile" class={`${CN}__add-file`}>
        <r-button color="primary" size="sm">
          <l10n group="media" tkey="upload-card__add-files" />
        </r-button>
      </div>
    );
  }

  render() {
    return (
      <card
        title={this.$t('common', 'header__upload')}
        v-slots={{ header: this.renderAddFileButton }}
      >
        <ul class={CN}>
          <li>{this.$t('media', 'upload-card__summary-completed', { count: this.completed })}</li>
          <li>{this.$t('media', 'upload-card__summary-progress', { count: this.inProgress })}</li>
          <li>{this.$t('media', 'upload-card__summary-queued', { count: this.queued })}</li>
          <li>{this.$t('media', 'upload-card__summary-failed', { count: this.failed })}</li>
        </ul>
      </card>
    );
  }

  mounted() {
    this.$nextTick(() => {
      const { addFile } = this.$refs;

      this.uploadService.addAllowedFormats(this.root.allAllowedFormats);

      if (addFile && addFile instanceof HTMLElement) {
        this.uploadService.assignBrowse(addFile);
      }
    });
  }
}
