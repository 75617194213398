import { RouteRecordRaw, RouterView } from 'vue-router';

import {
  RavnurRouteLeafConfig,
  EntityRouteKey,
  RavnurRouteBranchConfig,
  CATEGORIES,
  TAG,
} from '@/entity-route-config';

export const METADATA_FIELD: RavnurRouteLeafConfig = {
  tkey: 'menu__mfields',
  icon: 'text-fields',
  enabledRouteRestriction: 'isMetadataAvailable',
  routes: {
    [EntityRouteKey.CREATE]: 'CreateMetadataField',
    [EntityRouteKey.EDIT]: 'EditMetadataField',
    [EntityRouteKey.LIST]: 'MetadataFields',
  },
};

export const METADATA_TEMPLATE: RavnurRouteLeafConfig = {
  tkey: 'menu__mtemplates',
  icon: 'list',
  enabledRouteRestriction: 'isMetadataAvailable',
  routes: {
    [EntityRouteKey.CREATE]: 'CreateMetadataTemplate',
    [EntityRouteKey.EDIT]: 'EditMetadataTemplate',
    [EntityRouteKey.LIST]: 'MetadataTemplates',
  },
};

export const METADATA: RavnurRouteBranchConfig = {
  tkey: 'menu__metadata',
  icon: 'data-object',
  expanded: false,
  leafs: [TAG, CATEGORIES, METADATA_FIELD, METADATA_TEMPLATE],
  type: 'branch',
  route: '/metadata',
  order: 8,
};

export const ROUTES: RouteRecordRaw[] = [
  {
    path: '/metadata',
    component: RouterView,
    children: [
      {
        path: '/metadata-fields',
        component: RouterView,
        meta: { translations: ['metadata-fields', 'media'], config: METADATA_FIELD },
        children: [
          {
            path: '',
            name: 'MetadataFields',
            component: () => import('./pages/metadata-fields'),
          },
          {
            path: 'new',
            name: 'CreateMetadataField',
            component: () => import('./pages/create-metadata-field'),
          },
          {
            path: ':id',
            name: 'EditMetadataField',
            component: () => import('./pages/edit-metadata-field'),
          },
        ],
      },

      {
        path: '/metadata-templates',
        component: RouterView,
        meta: { translations: ['metadata-templates', 'media'], config: METADATA_TEMPLATE },
        children: [
          {
            path: '',
            name: 'MetadataTemplates',
            component: () => import('./pages/metadata-templates'),
          },
          {
            path: 'new',
            name: 'CreateMetadataTemplate',
            component: () => import('./pages/create-metadata-template'),
          },
          {
            path: ':id',
            name: 'EditMetadataTemplate',
            component: () => import('./pages/edit-metadata-template'),
          },
        ],
      },
      {
        path: '/tags',
        component: RouterView,
        meta: { translations: ['tags'], config: TAG },
        children: [
          {
            path: '',
            name: 'Tags',
            component: () => import('../../pages/tags-page/tags-page'),
          },
          {
            path: 'create',
            name: 'CreateTag',
            component: () => import('../../pages/create-tag/create-tag'),
          },
          {
            path: ':id',
            name: 'EditTag',
            component: () => import('../../pages/edit-tag/edit-tag'),
          },
        ],
      },
      {
        path: '/categories',
        component: RouterView,
        meta: { translations: ['categories'], config: CATEGORIES },
        children: [
          {
            path: '',
            name: 'Categories',
            component: () => import('../../pages/categories/categories'),
          },
          {
            path: 'create',
            name: 'CreateCategory',
            component: () => import('../../pages/create-category/create-category'),
          },
          {
            path: ':id',
            name: 'CategoryDetails',
            component: () => import('../../pages/category-details/category-details'),
          },
        ],
      },
    ],
  },
];
