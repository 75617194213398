import { $Props } from '@ravnur/core/typings/tsx';

import { Vue, Options } from 'vue-class-component';

import { UploadStatus, UploadInfo } from '@/types/Upload';

import './media-upload-progressbar.scss';

const CN = 'media-upload-progressbar';

class Props {
  item: UploadInfo;
}

class Emits {
  onPause: () => void;
  onResume: () => void;
  onRetry: () => void;
  onCancel: () => void;
}

@Options({
  emits: ['pause', 'resume', 'retry', 'cancel'],
})
export default class MediaUploadProgressbar extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;

  get mediaId() {
    return this.item.mediaId;
  }

  get isFailed() {
    return this.item.status === UploadStatus.FAILED;
  }

  get isFinished() {
    return this.item.status === UploadStatus.FINISHED;
  }

  get isProcessing() {
    return this.item.status === UploadStatus.PROCESSING;
  }

  get progress(): number {
    return this.item.progress;
  }

  get label() {
    if (!this.mediaId) return this.$t('media', 'download_preparing-file');
    if (this.isFailed) return this.$t('media', 'download__failed');
    if (this.isFinished) return this.$t('media', 'download__uploaded');

    return this.$t('media', 'download__uploading', { progress: this.progress.toFixed(2) });
  }

  private get renderActions() {
    if (this.isProcessing) {
      return (
        <>
          <r-button
            key="resume-paused"
            icon="play"
            mode="frameless"
            onclick={() => this.$emit('resume')}
            title={this.$t('media', 'download__play')}
            v-show={this.item.isPaused}
          />
          <r-button
            icon="pause"
            mode="frameless"
            onclick={() => this.$emit('pause')}
            title={this.$t('media', 'download__pause')}
            v-show={!this.item.isPaused}
          />
        </>
      );
    }

    if (this.isFailed) {
      return (
        <r-button
          key="restart-failed"
          icon="refresh"
          mode="frameless"
          onclick={() => this.$emit('retry')}
          title={this.$t('media', 'download__retry')}
        />
      );
    }
  }

  render() {
    return (
      <progressbar
        class={[`${CN}`, { [`${CN}__failed`]: this.isFailed }]}
        label={this.label}
        value={this.progress}
      >
        {this.mediaId && (
          <div class={`${CN}__actions`}>
            {this.renderActions}
            <r-button
              key="cancel-failed"
              icon="cancel"
              mode="frameless"
              onclick={() => this.$emit('cancel')}
              title={this.$t('common', 'action__cancel')}
              v-show={!this.isFinished}
            />
          </div>
        )}
      </progressbar>
    );
  }
}
