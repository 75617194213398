import { Vue, prop } from 'vue-class-component';
import sanitize from 'sanitize-html';

import './info-modal.scss';

const CN = 'info-modal';

class Props {
  header = prop<string>({ default: '' });
  text = prop<string>({ default: '' });
  close?: () => void;
}

export default class SendMessageModal extends Vue.with(Props) {
  render() {
    return (
      <popup class={CN} header={this.header} showFooter={false} onClose={this.close}>
        <div v-html={sanitize(this.text)} />
      </popup>
    );
  }
}
