import head from '@ravnur/core/filters/head';
import filter from '@ravnur/nanoutils/filter';
import flow from '@ravnur/nanoutils/flow';
import split from '@ravnur/nanoutils/string/split';

export const SEARCH_DEBOUNCE_TIME = +process.env.VUE_APP_SEARCH_DEBOUNCE_TIME || 600;

const site = flow(
  split('/'),
  filter((_: string) => !!_),
  head
);

export const BASE_API_PREFIX =
  process.env.VUE_APP_LOCAL_API === 'yes'
    ? process.env.VUE_APP_LOCAL_API_URL
    : '/' + (site(location.pathname) || 'cms') + process.env.VUE_APP_API_URL;

export const UNSAVED_REGISTRY_CTX = 'UNSAVED_REGISTRY_CTX';

export const CHANGE_PASSWORD_URL = `cms${process.env.VUE_APP_API_URL}`;
