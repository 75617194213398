import { Vue } from 'vue-class-component';

import ModalService, { IModalManager, ModalConstructor } from '@/helpers/modal.service';

export default class PopupManager extends Vue implements IModalManager {
  private modal: Nullable<ModalConstructor<any>> = null;
  private params: Dictionary<unknown> = {};
  private modalKey = 1;

  created() {
    ModalService.registry(this);
  }

  closeModal() {
    this.modal = null;
  }

  showModal<R>(Modal: ModalConstructor<R>, props: Dictionary<unknown>): Promise<R> {
    this.modal = Modal;
    this.params = props;
    this.modalKey = Date.now();
    return new Promise((resolve, reject) => {
      this.$nextTick(() => {
        ModalService.bus.on('*', (type, payload) => {
          ModalService.bus.all.clear();
          if (type === 'confirm') {
            resolve(payload as R);
          } else {
            reject(payload);
          }
        });
      });
    });
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <div id="modals" />
      </>
    );
  }

  renderModal() {
    const { modal: Modal } = this;
    if (!Modal) {
      return;
    }
    return (
      <Modal
        {...this.params}
        key={this.modalKey}
        ref="rootpopup"
        close={this.handleClosePopupEvent}
        confirm={this.handleConfirmPopupEvent}
      />
    );
  }

  private handleClosePopupEvent() {
    ModalService.bus.emit('close');
    this.$nextTick(this.destroyModal);
  }

  private handleConfirmPopupEvent(payload: unknown) {
    ModalService.bus.emit('confirm', payload);
    this.$nextTick(this.destroyModal);
  }

  private destroyModal() {
    this.modal = null;
    this.params = {};
  }
}
