import { some } from 'lodash';
import { withModifiers } from 'vue';
import { Options, setup, Vue } from 'vue-class-component';

import {
  RavnurRouteConfig,
  EntityRouteKey,
  isAvailableRoute,
  isRouteBranchConfig,
  RavnurRouteBranchConfig,
  RavnurRouteLeafConfig,
} from '@/entity-route-config';
import useSecurityStore from '@/store/security';
import BaseRepository, { PagedListResponse } from '@/repositories/base-repository';

import './app-nav-item.scss';
import { Watch } from '@ravnur/decorators';

const CN = 'app-nav-item';

class Props {
  item!: RavnurRouteConfig;
}

@Options({
  inject: {
    toggleNav: { from: 'toggleNav', default: null },
    isNavExpanded: { from: 'isNavExpanded', default: false },
  },
})
export default class AppNavItem extends Vue.with(Props) {
  declare toggleNav: Nullable<(boolean: boolean) => void>;

  private security = setup(() => useSecurityStore());
  private counters: Record<string, number> = {};
  private intervalId: Nullable<NodeJS.Timeout> = null;

  private get totalCounter() {
    return Object.values(this.counters).reduce((acc, val) => acc + val, 0);
  }

  private get order() {
    return this.item.order ?? 999;
  }

  @Watch('isNavExpanded')
  onNavExpandedChange(value: boolean) {
    if (!value) {
      (this.item as RavnurRouteBranchConfig).expanded = false;
    }
  }

  render() {
    return <>{this.renderFork(this.item)}</>;
  }

  private renderFork(item: RavnurRouteConfig) {
    if (isRouteBranchConfig(item)) {
      return this.renderBranch(item);
    }
    return this.renderLeaf(item);
  }

  private renderBranch(node: RavnurRouteBranchConfig) {
    if (!isAvailableRoute(this.security, node)) {
      return;
    }
    const cn = {
      [`${CN}__link`]: true,
      [`${CN}__link--node`]: true,
      [`${CN}__link--expanded`]: node.expanded,
    };
    const toggle = () => {
      if (!node.expanded && this.toggleNav) {
        this.toggleNav(true);
      }

      node.expanded = !node.expanded;
    };

    const leaves = node.leafs.map(this.renderFork);

    if (!some(leaves)) {
      return null;
    }

    return (
      <li class={CN} style={`order: ${this.order}`} title={this.$t('common', node.tkey)}>
        <router-link
          class={cn}
          to={node.route}
          onClick={toggle}
          {...{
            onClickCapture: withModifiers(() => void 0, ['prevent']),
          }}
        >
          <icon class={`${CN}__icon`} type={node.icon} />
          <l10n class={`${CN}__label`} group="common" tkey={node.tkey} />
          <span class={`${CN}__counter--branch`} v-show={this.totalCounter}>
            {this.totalCounter}
          </span>
          <icon class={[`${CN}__icon`, `${CN}__icon--expander`]} type="arrow-left" />
        </router-link>
        <ul class={`${CN}__submenu`}>{leaves}</ul>
      </li>
    );
  }

  private renderLeaf(node: RavnurRouteLeafConfig) {
    if (!isAvailableRoute(this.security, node)) {
      return null;
    }

    const { pingService } = node;

    if (pingService) {
      this.startPing(node);
    }

    const to = { name: node.routes[EntityRouteKey.LIST] };
    return (
      <li class={CN} style={`order: ${this.order}`} title={this.$t('common', node.tkey)}>
        <router-link class={`${CN}__link`} to={to}>
          <icon class={`${CN}__icon`} type={node.icon} />
          <l10n class={`${CN}__label`} group="common" tkey={node.tkey} />
          {pingService && (
            <span class={`${CN}__counter--leaf`} v-show={this.counters[pingService?.route]}>
              {this.counters[pingService?.route]}
            </span>
          )}
        </router-link>
      </li>
    );
  }

  async startPing(node: RavnurRouteLeafConfig) {
    if (this.intervalId || !node.pingService) {
      return;
    }

    const { route, filter, interval } = node.pingService;

    const ping = async () => {
      try {
        const repository = new BaseRepository<any, any, PagedListResponse<any>>(route);
        const response = await repository.load();
        const items = response.items.filter(filter);

        this.counters[route] = items.length;
      } catch (error) {
        console.error(error);
      }
    };

    await ping();

    this.intervalId = setInterval(ping, interval);
  }
}
