import { defineComponent } from 'vue';

import './forbidden.scss';
import useRootStore from '@/store/root';

const CN = 'forbidden';

const Forbidden = defineComponent({
  setup() {
    const store = useRootStore();
    const handleClick = async () => {
      await store.logout();
    };

    return () => {
      return (
        <>
          <div class={`${CN}__content`}>
            <div class={`${CN}__title`}>The page you opened is forbidden</div>
            <div class={`${CN}__desc`}>Try different credentials</div>
            <r-button class={`${CN}__btn`} color="primary" size="md" onClick={handleClick}>
              Go to login page
            </r-button>
          </div>
        </>
      );
    };
  },
});

export default Forbidden;
