import { AxiosRequestConfig } from 'axios';

import wait from './operation-helper';

import { BASE_API_PREFIX } from '@/constants';
import { get, post, put, remove } from '@/helpers/http';
import { fast } from '@/helpers/uploader';
import { OperationEntity } from '@/types/Operation';
import { IRepository } from '@/types/Repository';

export type PagedListResponse<E> = {
  items: E[];
  total: number;
};

// TODO: use @ravnur/http package instead

export default class BaseRepository<T extends Entity, Query = Dictionary<never>, ListResponse = T[]>
  implements IRepository<T>
{
  constructor(source: string) {
    Object.defineProperty(this, '_resource', {
      get: () => source,
    });
    Object.defineProperty(this, '_idField', {
      get: () => 'id',
    });
  }

  protected get _resource(): string {
    throw new Error('Unsupported operation exception');
  }

  protected get _idField(): keyof Entity {
    throw new Error('Unsupported operation exception');
  }

  public load(params: Nullable<Partial<Query>> = {}): Promise<ListResponse> {
    return get(this._resource, params);
  }

  public get(id: string, params: Dictionary<unknown> = {}): Promise<T> {
    return get(`${this._resource}/${id}`, params);
  }

  public async save(
    entity: Partial<T> | Array<Partial<T>>,
    params: Dictionary<unknown> = {}
  ): Promise<OperationEntity> {
    let promise;

    if (entity instanceof Array) {
      promise = put(`${this._resource}`, entity, params);
    } else {
      const id = entity[this._idField];
      promise = id
        ? put(`${this._resource}/${id}`, entity, params)
        : post(this._resource, entity, params);
    }

    try {
      const op = await promise;
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async saveMany<E extends Entity>(collections: Array<Unsaved<E>>) {
    return this.postCollection(null, collections);
  }

  public async removeMany<E extends Entity>(collections: Array<Unsaved<E>>) {
    const ids = collections.map((e) => e.id);
    return this._remove(this._resource, { params: { ids } });
  }

  public async postCollection<E extends Entity>(
    action: Nullable<string>,
    collections: Array<Unsaved<E>>
  ) {
    const source = action ? `${this._resource}/${action}` : this._resource;
    try {
      const op = await post(source, collections);
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async remove(id: string, options: AxiosRequestConfig = {}): Promise<OperationEntity> {
    return this._remove(`${this._resource}/${id}`, options);
  }

  protected async undo(
    id: string,
    action: string,
    options: AxiosRequestConfig = {}
  ): Promise<OperationEntity> {
    return this._remove(`${this._resource}/${id}/${action}`, options);
  }

  protected async action(
    { id }: Entity,
    action: string,
    data: unknown = {}
  ): Promise<OperationEntity> {
    try {
      const op = await post(`${this._resource}/${id}/${action}`, data);
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  protected async _remove(path: string, options: AxiosRequestConfig = {}) {
    try {
      const op = await remove(path, options);
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  protected async _upload<T>(
    file: File,
    suffix: string,
    data: Dictionary<string> = {},
    action: 'PUT' | 'POST' = 'POST'
  ): Promise<OperationEntity<T>> {
    const path = suffix
      ? `${BASE_API_PREFIX}${this._resource}/${suffix}`
      : `${BASE_API_PREFIX}${this._resource}`;
    try {
      const op: OperationEntity<T> = await fast(file, path, data, action);
      return await wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
