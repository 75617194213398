import { $Props } from '@ravnur/core/typings/tsx';
import { RButton$Props } from '@ravnur/core/ui-kit/button/button';
import { Vue, prop } from 'vue-class-component';

import ModalService from '@/helpers/modal.service';

const CN = 'action-button';

class Props {
  confirmer = prop({ default: '' });
  confirmText = prop({ default: '' });
  process: () => Promise<unknown>;
}

export type ActionButton$Props = $Props<Props> & RButton$Props;

export default class ActionButton extends Vue.with(Props) {
  public processing = false;

  render() {
    const content = this.$slots.default;
    const slots = content ? { default: content } : {};
    return (
      <r-button
        class={CN}
        {...this.$attrs}
        loading={this.processing}
        onclick={this.handleClickEvent}
        v-slots={slots}
      />
    );
  }

  private async handleClickEvent() {
    const { confirmer, confirmText } = this;
    if (confirmer) {
      const res = await ModalService.confirm(confirmer, confirmText ? { confirmText } : {});
      if (!res) {
        return;
      }
    }
    this.processing = true;

    try {
      await this.process();
    } catch (e) {
      // noop
    }

    this.processing = false;
  }
}
