import { cleanLocation } from '@ravnur/auth/helpers/location-cleaner';
import { refreshCookieName } from '@ravnur/auth/helpers/refresh-cookie-name';
import axios, { AxiosError } from 'axios';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

import { BaseAdminPlugin } from './base.admin.plugin';
import AdminPortal from './pages/admin/admin-portal';
import { ROUTES } from './routes';
import useRootStore from './store/root';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import 'normalize.css';
import './icons';
import './axios-interceptors';
import registryKit from './ui-kit/index';
import './override.scss';

// FIxes Buffer issue in RavnurC2PA module
import { Buffer } from 'buffer';

global.Buffer = Buffer;

const UNAUTH_CODE = 401;
const NOT_FOUND = 404;

(async function bootstrap() {
  const { cn } = cleanLocation('cn');
  if (typeof cn === 'string') {
    refreshCookieName(cn);
  }

  const router = createRouter({
    history: createWebHashHistory('/'),
    routes: ROUTES,
  });

  axios.interceptors.response.use(
    (_) => _,
    (error) => {
      const store = useRootStore();
      const code = _getErrorCode(error);
      if (code === UNAUTH_CODE) {
        store.logout();
      } else if (code === NOT_FOUND) {
        console.warn(error);
        console.trace('404');
        router.replace({ name: '404' });
      }
      return Promise.reject(error);
    }
  );

  const app = createApp(AdminPortal, { onReady, registerAppInsights, registerGTM });

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    environment: process.env.VUE_APP_DEPLOY_ENV,
    enabled: process.env.VUE_APP_DEPLOY_ENV === 'uat' || process.env.VUE_APP_DEPLOY_ENV === 'prod',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['hubtvuatadmin.ravnur.net', 'hubtv-admin.corp.ebay.com'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  app.use(createPinia());
  app.use(BaseAdminPlugin);
  app.use(router);
  registryKit(app);
  app.mount('#app');

  let isAuthenticated = false;
  router.beforeResolve(() => isAuthenticated);

  function onReady() {
    isAuthenticated = true;
    router.push(router.options.history.location);
  }

  function registerAppInsights(connectionString: string) {
    const appInsights = new ApplicationInsights({
      config: { connectionString },
    });
    const telemetryInitializer = (envelope: any) => {
      envelope.tags['ai.cloud.role'] = window.location.host;
      envelope.tags['ai.cloud.roleInstance'] = window.location.host;
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);
    appInsights.loadAppInsights();
    router.beforeResolve((to, from, next) => {
      appInsights.trackPageView({ name: to.path });
      next();
    });
  }

  function registerGTM(gtmId: string) {
    const script = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${gtmId}');
  `;

    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = script;
    document.head.appendChild(scriptTag);

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmId}`);
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');

    const noscriptTag = document.createElement('noscript');
    noscriptTag.appendChild(iframe); // Use innerHTML to set HTML content
    document.body.prepend(noscriptTag);
  }
})();

function _getErrorCode(error: AxiosError): Nullable<number> {
  const status = error?.response?.status;
  return typeof status === 'number' ? status : null;
}
