import isEqual from '@ravnur/nanoutils/isEqual';
import { ValidationRuleWithParams, ValidatorFn } from '@vuelidate/core';

type ValidationHelpers = {
  withParams: <P extends Dictionary<unknown>, T>(
    $params: P,
    $validator: ValidatorFn<T>
  ) => ValidationRuleWithParams<P, T>;
  regex: <T>(regex: RegExp) => ValidatorFn<T>;
};

const { regex } = (function (): ValidationHelpers {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const v: any = require('@vuelidate/validators');
  return v.helpers;
})();

export {
  numeric,
  email,
  url,
  ipAddress,
  or,
  not,
  required,
  requiredIf,
  maxValue,
  helpers,
} from '@vuelidate/validators';

const rtmpRegex =
  /^(?:rtmps?:\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const cidrRegex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/i;

export const urlWildcardRegex =
  /((http(s)?:\/\/)?(www\.)?(((([-a-zA-Z0-9@%_+~#=]){2,256})|\*)\.)?([-a-zA-Z0-9@%._+~#=]){2,256}\.[a-z]{2,6})|(^\*$)/;

export const rtmp = regex(rtmpRegex);
export const cidr = regex(cidrRegex);
export const urlWildcard = regex(urlWildcardRegex);
export const notEqual =
  <T>(etalon: T) =>
  (value: T) =>
    !isEqual(etalon, value);

export const noJS = (value: string) => {
  // eslint-disable-next-line
  return !value.match(/(\b)(on\S+)(\s*)=|javascript|<(|\/|[^\/>][^>]+|\/[^>][^>]+)>/gi);
};
