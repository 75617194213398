import { Vue, prop } from 'vue-class-component';

import './confirm.scss';

class Props {
  modalClasses = prop({ type: String, default: '' });
  confirmText = prop({ default: 'Confirm' });
  cancelText = prop({ type: String });
  message: string;
  close: () => void;
  confirm: (flag: boolean) => void;
}

const CN = 'confirm';

export default class Confirm extends Vue.with(Props) {
  private handleConfirmEvent() {
    this.confirm(true);
  }

  render() {
    return (
      <popup
        class={[CN, this.modalClasses]}
        closeLabel={this.cancelText || this.$t('common', 'action__cancel')}
        confirmLabel={this.confirmText}
        onClose={this.close}
        onConfirm={this.handleConfirmEvent}
      >
        {this.message}
      </popup>
    );
  }
}
