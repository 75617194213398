export enum Operation$Status {
  IN_PROGRESS = 0,
  DONE = 1,
  FAILED = 2,
}

export interface OperationEntity<T = unknown> {
  operationId: Nullable<string>;
  entityId: Nullable<string>;
  state: Operation$Status;
  errorReason: string[];

  data: T;
}

export function isOperation(data: unknown): data is OperationEntity {
  return (
    Object.prototype.hasOwnProperty.call(data, 'operationId') &&
    Object.prototype.hasOwnProperty.call(data, 'entityId') &&
    Object.prototype.hasOwnProperty.call(data, 'state')
  );
}
