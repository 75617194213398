export enum Permissions$Boolean {
  TRUE = 'True',
  FALSE = 'False',
}

export enum Permissions$Upload {
  NONE = 'None',
  WITHOUT_REVIEW = 'WithoutReview',
  REQUIRED_REVIEW = 'ReviewRequired',
}

export interface Permissions {
  downloadContent: Permissions$Boolean;
  rate: Permissions$Boolean;
  comment: Permissions$Boolean;
  tag: Permissions$Boolean;
  categoriesAdmin: Permissions$Boolean;
  rolesAdmin: Permissions$Boolean;
  createGroup: Permissions$Boolean;
  contentAdmin: Permissions$Boolean;
  reviewContent: Permissions$Boolean;
  manageUsers: Permissions$Boolean;
  groupsAdmin: Permissions$Boolean;
  upload: Permissions$Upload;
  accessToAdministration: Permissions$Boolean;
  tagsAdmin: Permissions$Boolean;
  shareLink: Permissions$Boolean;
  shareEmbedCode: Permissions$Boolean;
  editComments: Permissions$Boolean;
  runAudioIndexing: Permissions$Boolean;
  enablePublicPage: Permissions$Boolean;
  purgeItems: Permissions$Boolean;
  purgeUsers: Permissions$Boolean;
  fullAccess: Permissions$Boolean;
  receiveRegistrationRequest: Permissions$Boolean;
  setRetainItem: Permissions$Boolean;
  manageSettings: Permissions$Boolean;
}

export const DEFAULT_PERMISSIONS: Permissions = {
  downloadContent: Permissions$Boolean.FALSE,
  rate: Permissions$Boolean.FALSE,
  comment: Permissions$Boolean.FALSE,
  tag: Permissions$Boolean.FALSE,
  categoriesAdmin: Permissions$Boolean.FALSE,
  rolesAdmin: Permissions$Boolean.FALSE,
  createGroup: Permissions$Boolean.FALSE,
  contentAdmin: Permissions$Boolean.FALSE,
  reviewContent: Permissions$Boolean.FALSE,
  manageUsers: Permissions$Boolean.FALSE,
  groupsAdmin: Permissions$Boolean.FALSE,
  upload: Permissions$Upload.NONE,
  accessToAdministration: Permissions$Boolean.FALSE,
  tagsAdmin: Permissions$Boolean.FALSE,
  shareLink: Permissions$Boolean.FALSE,
  shareEmbedCode: Permissions$Boolean.FALSE,
  editComments: Permissions$Boolean.FALSE,
  runAudioIndexing: Permissions$Boolean.FALSE,
  enablePublicPage: Permissions$Boolean.FALSE,
  purgeItems: Permissions$Boolean.FALSE,
  purgeUsers: Permissions$Boolean.FALSE,
  fullAccess: Permissions$Boolean.FALSE,
  receiveRegistrationRequest: Permissions$Boolean.FALSE,
  setRetainItem: Permissions$Boolean.FALSE,
  manageSettings: Permissions$Boolean.FALSE,
};
