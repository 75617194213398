import OperationsRepository from './operation-repository';

import { OperationEntity, Operation$Status } from '@/types/Operation';

const repository = new OperationsRepository();
const HEARTBEAT_TIMEOUT = +process.env.VUE_APP_OPERATION_HEARTBEAT_TIMEOUT;

export default async function wait<T = unknown>(
  operation: OperationEntity<T>
): Promise<OperationEntity<T>> {
  return _process(operation);
}

function _process<T>(operation: OperationEntity<T>): Promise<OperationEntity<T>> {
  return new Promise((resolve, reject) => {
    if (operation.state === Operation$Status.IN_PROGRESS) {
      const id = operation.operationId;
      if (!id) {
        reject(new Error(`Incorrect operation structure: ${JSON.stringify(operation)}`));
        return;
      }
      setTimeout(async () => {
        try {
          const answer = await repository.get(id);
          const resp = await _process<T>(answer as OperationEntity<T>);
          resolve({ ...resp });
        } catch (e) {
          reject(e);
        }
      }, HEARTBEAT_TIMEOUT);
    } else if (operation.state === Operation$Status.DONE) {
      resolve(operation);
    } else if (operation.state === Operation$Status.FAILED) {
      reject(operation);
    }
  });
}
