import uidGenerator from './uid-generator';

import { BASE_API_PREFIX } from '@/constants';
import Resumable from '@/libs/Resumable';
import Logger from '@/logger';
import MediaRepository from '@/repositories/media-repository';
import { RootStore } from '@/store/root';
import { UploadStore } from '@/store/upload';
import { Media$Privacy, Media$Type } from '@/types/Media';
import { UploadStatus } from '@/types/Upload';
import * as Sentry from '@sentry/vue';
import { $processException } from './error-handler';
import doubleExtensionChecker from '@ravnur/helpers/double-extension-checker';

const logger = new Logger('Resumable');
const PATH =
  process.env.VUE_APP_MOCK_API === 'yes'
    ? 'http://localhost:5000/files/test' // You should run a mock server on port 5000
    : `${BASE_API_PREFIX}/upload`;
const repository = new MediaRepository();

export function factory(store: UploadStore, root: RootStore) {
  const resumable = new Resumable({
    fileType: [], // Update when used in component. Allowed files comes from BE response of auth endpoint
    target: PATH,
    chunkSize: 1024 * 1024,
    chunkRetryInterval: 1000,
    simultaneousUploads: 1, // Number of simultaneous chunk uploads
    permanentErrors: [400, 401, 403, 409, 415, 500, 501],
    generateUniqueIdentifier: () => uidGenerator('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'),
    chunkNumberParameterName: 'chunkNumber',
    chunkSizeParameterName: 'chunkSize',
    currentChunkSizeParameterName: 'currentChunkSize',
    totalSizeParameterName: 'totalSize',
    typeParameterName: 'type',
    identifierParameterName: 'identifier',
    fileNameParameterName: 'filename',
    relativePathParameterName: 'relativePath',
    totalChunksParameterName: 'totalChunks',
    testChunks: false,
    query(file) {
      const info = store.cacheByUid[file.uniqueIdentifier];
      const mediaId = (info && info.mediaId) || null;
      const autoStartProcess = (info && info.autoStartProcess) || true;
      let keepSubResources = info && info.keepSubResources;
      keepSubResources = typeof keepSubResources === 'boolean' ? keepSubResources : false;
      return { mediaId, attachmentType: 'sources', keepSubResources, autoStartProcess };
    },
  });

  resumable.on(
    'fileAdded',
    async (
      file,
      payload: Event | { mediaId: string; keepSubResources: boolean; autoStartProcess: boolean }
    ) => {
      const mediaId = payload instanceof Event ? null : payload.mediaId;
      const keepSubResources = payload instanceof Event ? false : payload.keepSubResources;
      const autoStartProcess = payload instanceof Event ? true : payload.autoStartProcess;
      const uid = file.uniqueIdentifier;
      const mediaType = getFileType(
        file.fileName.split('.').pop() || '',
        root.allowedVideoFormats,
        root.allowedAudioFormats
      );

      const allowedFormats = [...root.allowedVideoFormats, ...root.allowedAudioFormats];

      const hasDoubleExtension = doubleExtensionChecker(file.fileName, allowedFormats);

      if (hasDoubleExtension) {
        $processException(new Error('Invalid file - double extensions are not permitted'));

        return;
      }

      try {
        if (mediaId) {
          // we can upload new version for existing media
          // from video-versions-tab
          store.start({
            uid,
            mediaId,
            mediaType,
            isRefreshExistingSource: true,
            keepSubResources,
            autoStartProcess,
            isPaused: true,
            status: UploadStatus.INITIALIZED,
            progress: 0,
          });

          setTimeout(resumable.upload);
        } else {
          store.start({
            uid,
            mediaId: '',
            mediaType,
            autoStartProcess,
            isPaused: true,
            status: UploadStatus.INITIALIZED,
            progress: 0,
          });

          const op = await repository.save({
            title: file.fileName.substring(0, file.fileName.lastIndexOf('.')).substring(0, 150),
            type: mediaType,
            privacy: Media$Privacy.PUBLIC,
          });

          if (!op.entityId) {
            return;
          }

          store.addMediaId({ uid, mediaId: op.entityId });
          setTimeout(resumable.upload);
        }
      } catch (e) {
        logger.error(e);
        resumable.removeFile(file);
      }
    }
  );

  resumable.on('fileSuccess', async (file) => {
    const isPaused = !file.isComplete() && !file.isUploading();

    store.handleProgressEvent({
      uid: file.uniqueIdentifier,
      progress: 100,
      status: UploadStatus.FINISHED,
      isPaused,
    });
  });

  resumable.on('fileProgress', (file) => {
    const isPaused = !file.isComplete() && !file.isUploading();
    const progress = file.progress(false) * 100;

    store.handleProgressEvent({
      uid: file.uniqueIdentifier,
      progress,
      status: UploadStatus.PROCESSING,
      isPaused,
    });
  });

  resumable.on('fileError', (file, message) => {
    const isPaused = !file.isComplete() && !file.isUploading();
    const progress = file.progress(false) * 100;

    store.handleProgressEvent({
      uid: file.uniqueIdentifier,
      progress,
      status: UploadStatus.FAILED,
      isPaused,
    });

    logger.error('error on uploading file', file.file, message);

    Sentry.captureException(new Error('Upload failed'), (scope) => {
      const { fileName, size, uniqueIdentifier, resumableObj } = file;

      scope.clear();
      scope.setExtra('file', {
        fileName,
        size,
        uniqueIdentifier,
        progress: resumableObj.progress(),
      });

      return scope;
    });
  });

  return resumable;
}

function getFileType(ext: string, videoTypes: string[], audioTypes: string[]) {
  if (videoTypes.includes(ext.toLowerCase())) return Media$Type.VIDEO;
  if (audioTypes.includes(ext.toLowerCase())) return Media$Type.AUDIO;

  return Media$Type.VIDEO;
}
