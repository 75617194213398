import { defineComponent } from 'vue';
import useSecurityStore from '@/store/security';
import useRootStore from '@/store/root';
import ModalService from '@/helpers/modal.service';
import InfoModal from './info-modal';
import $t from '@ravnur/l10n/$t';

import './app-footer.scss';

const CN = 'app-footer';
const CURRENT_YEAR = new Date().getFullYear();

export default defineComponent({
  props: {
    isExpandedNav: { type: Boolean, required: true },
  },
  setup(props) {
    const security = useSecurityStore();
    const store = useRootStore();

    const openContactUsModal = async () => {
      return ModalService.showModal(InfoModal as any, {
        header: $t('common', 'footer__contact'),
        text: store.footerSettings?.contactUs,
      });
    };

    return () => (
      <footer
        class={{
          [CN]: true,
          [`${CN}--w-nav`]: props.isExpandedNav,
        }}
      >
        <ul class={`${CN}__links`}>
          {security.isTermSandConditionsAvailable && store.footerSettings?.termsAndConditions && (
            <li class={`${CN}__link-item`}>
              <a href={store.footerSettings.termsAndConditions} rel="noreferrer" target="_blank">
                <l10n group="common" tkey="footer__condition" />
              </a>
            </li>
          )}

          {security.isPrivacyPolicyAvailable && store.footerSettings?.privacyPolicy && (
            <li class={`${CN}__link-item`}>
              <a href={store.footerSettings.privacyPolicy} rel="noreferrer" target="_blank">
                <l10n group="common" tkey="footer__policy" />
              </a>
            </li>
          )}

          {security.isKnowledgeBase && store.footerSettings?.knowledgeBase && (
            <li class={`${CN}__link-item`}>
              <a href={store.footerSettings.knowledgeBase} rel="noreferrer" target="_blank">
                <l10n group="common" tkey="footer__knowledge" />
              </a>
            </li>
          )}

          {security.isContactInfoAvailable && store.footerSettings?.contactUs && (
            <li class={`${CN}__link-item`}>
              <r-button mode="frameless" onclick={openContactUsModal}>
                <l10n group="common" tkey="footer__contact" />
              </r-button>
            </li>
          )}
        </ul>
        <div class={`${CN}__rights-block`}>
          © {CURRENT_YEAR}
          <a
            class={`${CN}__ravnur-link`}
            href="https://www.ravnur.com/"
            rel="noreferrer"
            target="_blank"
          >
            Ravnur Inc.
          </a>
          All rights reserved.
        </div>
      </footer>
    );
  },
});
