import { Media$Type } from './Media';

// export interface IUploadStore {
//   cacheByUid: Dictionary<UploadInfo>;
//   cacheByMediaId: Dictionary<UploadInfo>;

//   start: (info: UploadInfo) => void;
//   handleProgressEvent: (event: UploadProgressEvent) => void;
// }

export enum UploadStatus {
  INITIALIZED = 'initialized',
  PROCESSING = 'processing',
  FINISHED = 'finished',
  FAILED = 'failed',
}

export interface UploadInfo {
  uid: string;
  mediaId: string;
  mediaType: Media$Type;
  progress: number;
  isPaused: boolean;
  isRefreshExistingSource?: boolean;
  keepSubResources?: boolean;
  autoStartProcess?: boolean;
  status: UploadStatus;
}

export interface UploadProgressEvent {
  uid: string;
  progress: number;
  status: UploadStatus;
  isPaused: boolean;
}
