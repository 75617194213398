import { $Props } from '@ravnur/core/typings/tsx';
import { Vue, Options } from 'vue-class-component';

import CommunicatorHelper from '@/helpers/communicator';

import './embed-app.scss';
const CN = 'embed-app';

class Props<D> {
  src: string;
  value?: D;
}

type Emits<D> = {
  onInput: (data: D) => void;
};

export type EmbedApp$Props<D> = $Props<Props<D>, Emits<D>>;

@Options({
  name: 'embed-app',
  emits: ['input'],
  watch: {
    value: {
      handler(this: EmbedApp) {
        this.handleValueChanged();
      },
    },
  },
})
export default class EmbedApp extends Vue.with(Props) {
  private height = 0;

  mounted() {
    const child = this.defaultView();
    if (child) {
      child.addEventListener('message', this.handleMessage);
    }
  }

  beforeUnmount() {
    const child = this.defaultView();
    if (child) {
      child.removeEventListener('message', this.handleMessage);
    }
  }

  protected handleValueChanged() {
    const communicator = this.getCommunicator();
    if (communicator) {
      communicator.setData(this.value);
    }
  }

  render() {
    const { src, height } = this;
    return (
      <iframe
        class={CN}
        frameborder="0"
        seamless={true}
        src={src}
        style={{ height: `${height}px` }}
        onLoad={this.handleValueChanged}
      />
    );
  }

  private handleMessage(ev: MessageEvent) {
    if (ev.data === 'resize') {
      this.handleResizeEvent();
    } else if (ev.data === 'input') {
      this.handleInputEvent();
    } else if (ev.data === 'ready') {
      this.handleValueChanged();
      this.handleResizeEvent();
    }
  }

  private handleResizeEvent() {
    const child = this.defaultView();
    if (!child) {
      return;
    }
    this.height = 0;
    this.$nextTick(() => {
      this.height = child.document.body.scrollHeight;
    });
  }

  private handleInputEvent() {
    const communicator = this.getCommunicator();
    if (communicator) {
      this.$emit('input', communicator.getData());
    }
  }

  private defaultView(): WindowProxy | null {
    const { $el } = this;
    if (!($el instanceof HTMLIFrameElement)) {
      return null;
    }

    if ($el.contentDocument && $el.contentDocument.defaultView) {
      return $el.contentDocument.defaultView;
    }
    return null;
  }

  private getCommunicator() {
    const child = this.defaultView();
    if (!child) {
      return;
    }

    const { communicator } = child.window;
    return isCommunicator(communicator) ? communicator : null;
  }
}

function isCommunicator<T>(h: unknown): h is CommunicatorHelper<T> {
  if (!h || typeof h !== 'object') {
    return false;
  }

  const helper = h as Dictionary<unknown>;

  if (typeof helper.setData !== 'function') {
    return false;
  }

  if (typeof helper.getData !== 'function') {
    return false;
  }

  if (typeof helper.setter !== 'function') {
    return false;
  }

  if (typeof helper.getter !== 'function') {
    return false;
  }

  return true;
}
