import BaseNotifications from '@ravnur/notifications/notifications';
import { Notification$Type, RavnurNotification } from '@ravnur/notifications/typings';
import { Vue, Options } from 'vue-class-component';

import './notifications.scss';

const CN = 'notifications';

const ICONS: { [key in Notification$Type]: string } = {
  info: 'info',
  error: 'warning',
  done: 'check',
};

const MODES: { [key in Notification$Type]: 'info' | 'danger' | 'success' } = {
  info: 'info',
  error: 'danger',
  done: 'success',
};

@Options({
  name: 'notifications',
})
export default class Notifications extends Vue {
  private renderItem({
    notification,
    hide,
  }: {
    notification: RavnurNotification;
    hide: (notification: RavnurNotification) => void;
  }) {
    return (
      <li key={notification.timestamp}>
        <alert class={`${CN}__item`} mode={MODES[notification.type]}>
          <icon class={`${CN}__icon`} type={ICONS[notification.type]} />
          <span class={`${CN}__message`}>{notification.message}</span>
          <r-button
            class={`${CN}__close-btn`}
            color="white"
            icon="close"
            mode="frameless"
            onclick={() => hide(notification)}
          />
        </alert>
      </li>
    );
  }

  render() {
    // TODO: corrent typings
    const BN: any = BaseNotifications;
    return <BN class={CN} v-slots={{ notification: this.renderItem }} />;
  }
}
