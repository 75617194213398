import BaseRepository, { PagedListResponse } from './base-repository';

import { get, post } from '@/helpers/http';
import { Job } from '@/types/Job';
import { Job$Status } from '@ravnur/shared/types/Job';

if (process.env.VUE_APP_MOCK_API === 'yes') {
  (function _bootstrap() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const adapert = require('../mocks/index').default;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fnc = require('../mocks/jobs').default;
    fnc(adapert);
  })();
}

export interface JobsRepositoryQueryParams {
  id: string[];
  mediaId: string[];
  sortField: string;
  sortType: string;
  count: number;
  state: Job$Status[];
  offset: number;
  sort: string;
}

export default class JobsRepository extends BaseRepository<
  Job,
  JobsRepositoryQueryParams,
  PagedListResponse<Job>
> {
  constructor() {
    super('/job');
  }

  public monitor(params: Nullable<Partial<JobsRepositoryQueryParams>> = {}): Promise<Job[]> {
    return get(`${this._resource}/monitor`, params);
  }

  public retry(jobId: string): Promise<Operation<Job>> {
    return post(`${this._resource}/${jobId}/retry`, {});
  }

  public cancel(jobId: string) {
    return post(`${this._resource}/${jobId}/cancel`, {});
  }
}
