import curry2, { CurriedFunction1 } from './curry2';
import isNil from './isNil';

interface FindFnc {
  <T>(iteratees: Predicate<T>): CurriedFunction1<T[], T | null>;
  <T>(iteratees: Predicate<T>, arr: T[]): T | null;
}

const find: FindFnc = curry2(_find);
export default find;

function _find<T>(iteratees: Predicate<T>, arr: T[]): T | null {
  const item = arr.find(iteratees);
  return isNil(item) ? null : item;
}
