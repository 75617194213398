import { RouteRecordRaw, RouterView } from 'vue-router';

import * as config from './entity-route-config';
import ErrorPage from './pages/error-page.vue';
import Upload from './pages/upload/upload';

export const ROUTES: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'root',
    component: RouterView,
    children: [
      {
        path: '',
        redirect: { name: 'Dashboard' },
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('./pages/dashboard/dashboard'),
        meta: { config: config.DASHBOARD },
      },

      {
        path: 'transcoding',
        name: 'Transcoding',
        component: () => import('./pages/transcoding/transcoding'),
        meta: { config: config.TRANSCODING },
      },

      {
        path: '/home/featured-carousel',
        component: RouterView,
        meta: { translations: ['featured_carousel'], config: config.FEATURED_CAROUSEL },
        children: [
          {
            path: '',
            name: 'FeaturedCarousel',
            component: () => import('./pages/featured-carousel/featured-carousel-list'),
          },
          {
            path: 'create',
            name: 'CreateFeaturedCarousel',
            component: () => import('./pages/featured-carousel/featured-carousel-create'),
          },
          {
            path: 'edit/:id',
            name: 'EditFeaturedCarousel',
            component: () => import('./pages/featured-carousel/featured-carousel-edit'),
          },
        ],
      },

      {
        path: '/videos',
        component: RouterView,
        meta: { translations: ['media', 'video-editors'], config: config.VIDEO },
        children: [
          {
            path: '',
            name: 'Videos',
            component: () => import('./pages/videos/videos'),
          },
          {
            path: ':id',
            name: 'VideoDetails',
            component: () => import('./pages/video-details/video-details'),
          },
          {
            path: ':id/editors',
            name: 'VideoEditors',
            component: () => import('./pages/video-editors/video-editors'),
          },
        ],
      },
      {
        path: '/upload',
        name: 'Upload',
        meta: { translations: ['media'] },
        component: Upload,
      },
      {
        path: '/audios',
        component: RouterView,
        meta: { translations: ['media'], config: config.AUDIO },
        children: [
          {
            path: '',
            name: 'Audios',
            component: () => import('./pages/audios/audios'),
          },
          {
            path: ':id',
            name: 'AudioDetails',
            component: () => import('./pages/audio-details/audio-details'),
          },
        ],
      },

      {
        path: '/documents',
        component: RouterView,
        meta: { translations: ['media'], config: config.DOCUMENT },
        children: [
          {
            path: '',
            name: 'Documents',
            component: () => import('./pages/documents/documents'),
          },
          {
            path: ':id',
            name: 'DocumentDetails',
            component: import('./pages/document-details/document-details'),
          },
        ],
      },

      {
        path: '/playlists',
        component: RouterView,
        meta: { translations: ['media'], config: config.PLAYLIST },
        children: [
          {
            path: '',
            name: 'Playlists',
            component: () => import('./pages/playlists/playlists'),
          },
          {
            path: 'create',
            name: 'CreatePlaylist',
            component: () => import('./pages/create-playlist/create-playlist'),
          },
          {
            path: ':id',
            name: 'PlaylistDetails',
            component: () => import('./pages/playlist-details/playlist-details'),
          },
        ],
      },

      {
        path: '/settings',
        component: RouterView,
        meta: { config: config.SETTINGS },
        children: [
          {
            path: '/configurations',
            component: RouterView,
            meta: { translations: ['configurations'], config: config.CONFIGURATIONS },
            children: [
              {
                path: '',
                name: 'Configurations',
                component: () => import('./pages/configurations/configurations'),
              },
            ],
          },
          {
            path: '/branding',
            component: RouterView,
            meta: { translations: ['branding'], config: config.BRANDING },
            children: [
              {
                path: '',
                name: 'Branding',
                component: () => import('./pages/branding/branding'),
              },
            ],
          },

          {
            path: '/roles',
            component: RouterView,
            meta: { translations: ['roles'], config: config.ROLE },
            children: [
              {
                path: '',
                name: 'Roles',
                component: () => import('./pages/roles/roles'),
              },
              {
                path: 'create',
                name: 'CreateRole',
                component: () => import('./pages/create-role/create-role'),
              },
              {
                path: ':id',
                name: 'EditRole',
                component: () => import('./pages/edit-role/edit-role'),
              },
            ],
          },

          {
            path: '/languages',
            component: RouterView,
            meta: { translations: ['cc-auto-translations'], config: config.CC_AUTO_TRANSLATION },
            children: [
              {
                path: '',
                name: 'Languages',
                component: () => import('./pages/cc-auto-translation/cc-auto-translation'),
              },
            ],
          },
        ],
      },

      {
        path: '/members',
        component: RouterView,
        meta: { translations: ['members'], config: config.MEMBER },
        children: [
          {
            path: '',
            name: 'Members',
            component: () => import('./pages/members/members'),
          },
          {
            path: '/members/:id',
            name: 'MemberDetails',
            component: () => import('./pages/member-details/member-details'),
          },
          {
            path: '/members/create',
            name: 'MemberCreate',
            component: () => import('./pages/member-create/member-create'),
          },
        ],
      },

      {
        path: '/mail-templates',
        component: RouterView,
        meta: {
          translations: ['mail-templates'],
          config: config.MAIL_TEMPLATE,
        },
        children: [
          {
            path: '',
            name: 'MailTemplates',
            component: () => import('./pages/mail-templates/mail-templates'),
          },
          {
            path: ':id',
            name: 'EditMailTemplate',
            component: () => import('./pages/edit-mail-template/edit-mail-template'),
          },
        ],
      },

      {
        path: '/groups',
        component: RouterView,
        meta: { translations: ['groups'], config: config.GROUPS },
        children: [
          {
            path: '',
            name: 'Groups',
            component: () => import('./pages/groups/groups'),
          },
          {
            path: 'create',
            name: 'CreateGroup',
            component: () => import('./pages/create-group/create-group'),
          },
          {
            path: ':id',
            name: 'GroupDetails',
            component: () => import('./pages/group-details/group-details'),
            meta: {
              translations: ['groups', 'group-members', 'send-message', 'media', 'folders'],
              config: config.GROUPS,
            },
          },
        ],
      },
      {
        path: '/home',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'Home',
            component: RouterView,
          },
          {
            path: '/featured-carousel',
            component: RouterView,
            meta: { translations: ['featured_carousel'], config: config.FEATURED_CAROUSEL },
            children: [
              {
                path: '',
                name: 'FeaturedCarousel',
                component: () => import('./pages/featured-carousel/featured-carousel-list'),
              },
              {
                path: 'create',
                name: 'CreateFeaturedCarousel',
                component: () => import('./pages/featured-carousel/featured-carousel-create'),
              },
              {
                path: 'edit/:id',
                name: 'EditFeaturedCarousel',
                component: () => import('./pages/featured-carousel/featured-carousel-edit'),
              },
            ],
          },

          {
            path: '/featured-video',
            name: 'FeaturedVideos',
            component: () => import('./pages/featured-video/featured-video'),
            meta: { translations: ['media'], config: config.FEATURED_VIDEOS },
          },
        ],
      },
      {
        path: '/tools',
        component: RouterView,
        meta: { config: config.TOOLS },
        children: [
          {
            path: '/zoom',
            name: 'ZoomImport',
            meta: { translations: ['zoom-import', 'media'], config: config.ZOOM_IMPORT },
            component: () => import('./pages/tools/zoom-import/zoom-import'),
          },
          {
            path: '/change-owner',
            name: 'ChangeOwner',
            meta: { config: config.CHANGE_OWNER },
            component: () => import('./pages/tools/change-owner/change-owner'),
          },
          {
            path: '/domains',
            component: RouterView,
            meta: { config: config.DOMAIN },
            children: [
              {
                path: '',
                name: 'Domains',
                component: () => import('./pages/whitelisted-domain/whitelisted-domain-list'),
              },
              {
                path: 'create',
                name: 'CreateDomain',
                component: () => import('./pages/whitelisted-domain/whitelisted-domain-create'),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: '/404',
    name: '404',
    props: {
      code: '404',
    },
    component: ErrorPage,
  },
  {
    path: '/403',
    name: '403',
    props: {
      code: '403',
    },
    component: ErrorPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'others',
    props: {
      code: '404',
    },
    component: ErrorPage,
  },
];
