import { keyById } from '@ravnur/nanoutils/keyBy';
import { reactive } from 'vue';

import BaseRepository, { PagedListResponse as LR } from '@/repositories/base-repository';
import { IService } from '@/types/IService';
import { OperationEntity } from '@/types/Operation';

type ThesaurusService$Query = {
  count: number;
  offset: number;
};

export class ThesaurusService<E extends Entity, ED extends E = E> implements IService<E, ED> {
  public loading = false;

  private items: E[] = [];
  private dirty = false;

  constructor(public readonly repository: BaseRepository<ED, ThesaurusService$Query, LR<E>>) {}

  public get list(): E[] {
    return this.items;
  }

  public get cache(): Dictionary<E> {
    return keyById(this.items);
  }

  public async load() {
    const { loading, dirty } = this;

    if (loading || dirty) {
      return;
    }
    this.startLoading();

    try {
      const { items } = await this.repository.load({ count: 1000, offset: 0 });
      this.set(items);
    } catch (e) {
      return Promise.reject(e);
    }

    this.stopLoading();
  }

  public async save(entity: Partial<ED>): Promise<OperationEntity> {
    try {
      const op = await this.repository.save(entity);
      if (!op || !op.entityId) {
        return op;
      }

      const saved = await this.repository.get(op.entityId);
      this.change(saved);
      return op;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async get(id: string) {
    return this.repository.get(id);
  }

  public async remove(entity: Entity) {
    try {
      await this.repository.remove(entity.id);
      this.mRemoveMany([entity]);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeMany(list: E[]) {
    try {
      await this.repository.postCollection('delete', list);
      this.mRemoveMany(list);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public logout() {
    this.items = [];
    this.dirty = false;
  }

  private change(entity: E) {
    const { items, dirty } = this;
    if (!dirty) {
      return;
    }
    const cache = keyById(items);
    cache[entity.id] = entity;
    this.items = Object.keys(cache).map((id) => cache[id]);
  }

  private set(items: E[]) {
    this.items = items;
  }

  private mRemoveMany(items: Entity[]) {
    const cache = keyById(items);
    this.items = this.items.filter((e) => !cache[e.id]);
  }

  private startLoading() {
    this.loading = true;
  }

  private stopLoading() {
    this.loading = false;
    this.dirty = true;
  }
}

export function createThesaurusService<E extends Entity, ED extends E = E>(
  src: BaseRepository<ED, ThesaurusService$Query, LR<E>> | string
): ThesaurusService<E, ED> {
  const repository =
    typeof src === 'string' ? new BaseRepository<ED, ThesaurusService$Query, LR<E>>(src) : src;

  const service = new ThesaurusService<E, ED>(repository);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reactive(service) as any as ThesaurusService<E, ED>;
}
