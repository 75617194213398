// TODO: use commmon
import { createAuthenticationInterseptors } from '@ravnur/auth/helpers/axios-interceptors';
import axios from 'axios';
import { stringify } from 'qs';

import toBackendFormat from '@/transformers/http/toBackendFormat';
import toFrontendFormat from '@/transformers/http/toFrontendFormat';

const authInterceptors = createAuthenticationInterseptors();

axios.interceptors.request.use(authInterceptors.request);

axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => stringify(params, { arrayFormat: 'repeat' });
  config.params = toBackendFormat(config.params);
  config.data = toBackendFormat(config.data);
  config.withCredentials = true;
  return config;
});

axios.interceptors.response.use(
  (resp) => {
    resp.data = toFrontendFormat(resp.data);
    return resp;
  },
  (err) => Promise.reject(toFrontendFormat(err))
);
axios.interceptors.response.use(authInterceptors.response);
