import { renderSlot } from 'vue';
import { Vue } from 'vue-class-component';

import './column.scss';

const CN = 'column';

export default class Column extends Vue {
  render() {
    return <div class={CN}>{renderSlot(this.$slots, 'default')}</div>;
  }
}
