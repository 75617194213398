import filter from '@ravnur/nanoutils/filter';
import flow from '@ravnur/nanoutils/flow';
import keyBy from '@ravnur/nanoutils/keyBy';
import { defineStore } from 'pinia';

import JobsRepository from '@/repositories/jobs-repository';
import { Job, Job$Status, isRunningJob } from '@/types/Job';

const repository = new JobsRepository();
const keyByMediaId: (arr: Job[]) => Dictionary<Job> = keyBy('mediaId');
const runningAsCache = flow(filter(isRunningJob), keyByMediaId);

type State = {
  list: Job[];
  mediaIds: string[];
  log: Record<string, Job>;
};

const useJobsStore = defineStore({
  id: 'jobs',

  state: (): State => ({ list: [], mediaIds: [], log: {} }),

  getters: {
    all: (state) => keyByMediaId(state.list),
    running: (state) => runningAsCache(state.list),
  },

  actions: {
    async add(jobId: string) {
      try {
        const job = await repository.get(jobId);
        this.update(job);
        return job;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async cancel(job: Job) {
      try {
        await repository.cancel(job.id);
        this.update({ ...job, state: Job$Status.CANCELED, progress: 0 });
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async retry(job: Job) {
      try {
        const retryResponse = await repository.retry(job.id);
        this.update(retryResponse.data);
        return retryResponse;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async remove(job: Job) {
      try {
        await repository.remove(job.id);
        this.list = this.list.filter((item) => item.id !== job.id);
        delete this.log[job.mediaId];
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async refresh() {
      const id = this.list.filter((j) => j.state === Job$Status.RUNNING).map((j) => j.id);
      const jobs = await repository.monitor({ id, mediaId: this.mediaIds });

      this.logJobs(jobs);

      this.list = jobs;

      return jobs;
    },

    logout() {
      this.list = [];
    },

    logJobs(jobs: Job[]) {
      this.log = {
        ...this.log,
        ...keyByMediaId(jobs),
      };
    },

    update(job: Job) {
      const index = this.list.findIndex((j) => j.mediaId === job.mediaId);
      const newList = [...this.list];
      const logJob = this.log[job.mediaId];

      if (index !== -1) {
        newList[index] = {
          ...this.list[index],
          ...job,
        };
      } else {
        newList.push({
          ...logJob,
          ...job,
        });
      }

      this.logJobs(newList);

      this.list = newList;
    },
  },
});

export default useJobsStore;
