export function fast<T>(
  file: File,
  url: string,
  data: Dictionary<string | Blob> = {},
  action: 'PUT' | 'POST' = 'POST'
): Promise<T> {
  // if (process.env.NODE_ENV === 'development') {
  //   url = 'http://localhost:5000/files/test';
  //   action = 'POST';
  // }

  const params: string[] = [];
  const form = new FormData();
  _objMap(data, (key: string, value: string | Blob) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (value && typeof value === 'object' && typeof value.toString !== 'function') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (value instanceof File) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        form.append(key, value, value.name);
      } else {
        form.append(key, JSON.stringify(value));
      }
    } else {
      form.append(key, value);
      if (typeof value === 'string') {
        params.push([encodeURIComponent(key), encodeURIComponent(value)].join('='));
      }
    }
  });
  form.append('file', file, file.name);
  const xhr = new XMLHttpRequest();
  xhr.open(action, `${url}?${params.join('&')}`);

  return new Promise((resolve, reject) => {
    xhr.onload = listener;
    xhr.onerror = listener;
    xhr.onabort = listener;
    xhr.ontimeout = listener;
    xhr.send(form);

    function listener(e: unknown) {
      const resp = xhr.responseText;
      if (resp) {
        try {
          resolve(JSON.parse(resp));
        } catch (err) {
          return reject(err);
        }
      }
      return reject(e);
    }
  });
}

function _objMap(obj: Dictionary<unknown>, callback: (key: string, value: string | Blob) => void) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'string' || value instanceof Blob) {
      callback(key, value);
    }
  });
}
