import find from '@ravnur/nanoutils/find';
import head from '@ravnur/nanoutils/head';

import useSecurityStore from '@/store/security';
import { RavnurApplication } from '@/types/Auth';
import Forbidden from '@/pages/forbidden/forbidden';
import { computed, defineComponent, onMounted } from 'vue';
import { Permissions$Boolean } from '@/types/Permissions';

const ApplicationPermissionsChecker = defineComponent({
  setup(props, ctx) {
    const security = useSecurityStore();
    const currentApplication = computed(() => security.currentApplication);
    const fallbackApplication = computed(() => {
      const { applications, user } = security;
      const adminApplications = applications.filter(
        ({ adminUrl, userPermissions }) =>
          adminUrl && userPermissions.accessToAdministration === Permissions$Boolean.TRUE
      );
      if (adminApplications.length && user) {
        const predicate = (a: RavnurApplication) => a.id === user.defaultSiteId;
        return (
          (user?.defaultSiteId && find(predicate, adminApplications)) || head(adminApplications)
        );
      }
    });

    onMounted(() => {
      const { isOpenAdminPanelAllowed } = security;
      if (fallbackApplication.value && (!currentApplication.value || !isOpenAdminPanelAllowed)) {
        window.location.replace(`${fallbackApplication.value.adminUrl}/`);
      }
    });

    return () => {
      return !fallbackApplication.value ? (
        <Forbidden data-testid="Forbidden" />
      ) : (
        ctx.slots.default?.()
      );
    };
  },
});

export default ApplicationPermissionsChecker;
