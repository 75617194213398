import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { ROUTES } from '@/routes';

export default defineComponent({
  setup() {
    const router = useRouter();
    ROUTES.forEach((r) => router.addRoute(r));
  },
});
