import { removeAuthToken } from '@ravnur/auth/helpers/remove-auth-token';
import { defineStore } from 'pinia';

import useSecurityStore from './security';

import AuthRepository from '@/repositories/auth-repository';
import { AttachmentDescription } from '@/types/Attachment';
import { Breadcrumb } from '@/types/Breadcrumb';
import { Media$Type } from '@/types/Media';
import { FooterSettings } from '@/types/FooterSettings';
import ModalService from '@/helpers/modal.service';
import $t from '@ravnur/l10n/$t';
import useUploadStore from '@/store/upload';
import { PortalConfigurations } from '@ravnur/shared/types/Configurations';

type State = {
  configurations: null | PortalConfigurations;
  breadcrumbs: Breadcrumb[];
};

const useRootStore = defineStore({
  id: 'root',
  state: (): State => ({ breadcrumbs: [], configurations: null }),
  actions: {
    changeBreadcrumbs(breadcrumbs: Breadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
    setPortalConfiguration(configurations: PortalConfigurations) {
      this.configurations = configurations;
    },
    async logout() {
      const security = useSecurityStore();
      if (!security.isAuth) {
        return;
      }

      const upload = useUploadStore();

      if (upload.isProcessing) {
        const msg = $t('common', 'upload-interrupt');
        try {
          const modalOptions = {
            header: $t('common', 'uploading-in-progress'),
            confirmText: $t('common', 'upload-interrupt-confirm'),
            cancelText: $t('common', 'upload-interrupt-decline'),
          };
          await ModalService.confirm(msg, modalOptions);
          upload.clear();
        } catch (e) {
          return;
        }
      }

      try {
        const repository = new AuthRepository();
        await repository.logout();
        removeAuthToken();
      } catch (e) {
        location.href = location.href + '?successCode=success_logout';
        location.reload();
      }
      security.failedAuthenticated();
    },
  },
  getters: {
    footerSettings: (state): FooterSettings | undefined => state.configurations?.settings,
    mediaAttachments: (state): { [key in Media$Type]?: AttachmentDescription[] } => ({
      [Media$Type.VIDEO]: state.configurations?.media?.video?.attachments || [],
    }),
    allAllowedFormats: (state): string[] => {
      let allFormats: string[] = [];
      const videoFormats: string =
        state.configurations?.media?.video?.videoSettings?.allowedFormats || '';
      const audioFormats: string =
        state.configurations?.media?.audio?.audioSettings?.allowedFormats || '';

      allFormats = [...videoFormats.split(',').map((f: string) => f.replace('.', ''))];

      audioFormats
        .split(',')
        .map((f: string) => f.replace('.', ''))
        .forEach((f: string) => !allFormats.includes(f) && allFormats.push(f));

      return allFormats;
    },
    allowedVideoFormats: (state): string[] =>
      state.configurations?.media?.video?.videoSettings?.allowedFormats
        .split(',')
        .map((f: string) => f.replace('.', '')) || [],
    allowedAudioFormats: (state): string[] =>
      state.configurations?.media?.audio?.audioSettings?.allowedFormats
        .split(',')
        .map((f: string) => f.replace('.', '')) || [],
    liveEventAttachments: (state) => state.configurations?.live?.event?.attachments || [],
    liveChannelSettings: (state) => state.configurations?.live.liveChannel,
  },
});

export default useRootStore;
export type RootStore = ReturnType<typeof useRootStore>;
