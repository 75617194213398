import { $Props } from '@ravnur/core/typings/tsx';
import { renderSlot } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

import './card.scss';

const CN = 'card';

// TODO: remove component from ui-kit
// TODO: use composition API instead
class Props {
  title = prop({ default: '' });
}

export type Card$Props = $Props<Props>;

@Options({
  name: 'card',
})
export default class Card extends Vue.with(Props) {
  render() {
    const { $slots, title } = this;
    return (
      <main class={CN}>
        <div class={`${CN}__header`}>
          <h2 class={`${CN}__title`}>{title}</h2>
          <div class={`${CN}__state`}>{renderSlot($slots, 'header')}</div>
        </div>
        <div class={`${CN}__body`}>{renderSlot($slots, 'default')}</div>
      </main>
    );
  }
}
